.tooltip {
    position: relative;

    &__flip {
        @extend .entry-box;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: rotateX(90deg) translate(0%, -50%);
        transform-origin: 0% 0%;
        text-align: left;
        z-index: 1;
        opacity: 0;
        transition: all 0.6s ease;
        font-family: $ff__primary;
    }

    .fa {
        cursor: pointer;
    }

    &:hover {
        .tooltip__flip {
            transform: rotateX(0deg) translate(0, -50%);
            opacity: 1;
            transition-delay: 0.35s;
        }
    }
}
