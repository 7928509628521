// ==========================================================================
// Cards
// ==========================================================================

@mixin btn(
    $class: '.btn',
    $btn-border: false,
    $btn-radius: false,
    $btn-padding: $padding,
    $btn-margin: $margin
) {

	#{$class} {

		font-size: 1rem;
        line-height: 1.4;

		display: inline-block;
		max-width: 100%;

        @if $btn-border {
    		border: #{$btn-border};
        }
        @if $btn-radius {
            border-radius:  $btn-radius;
        }

		padding: $btn-padding;
		margin-bottom: $btn-margin;

        &--left-icon {
            @extend #{$class};
            .fa {
                line-height: $lh;
                float:left;
                padding: $btn-padding;
                margin: -$btn-padding;
                margin-right: $btn-padding;
                @if $btn-border {
                    border-right: $btn-border;
                }
            }
        }
        &--right-icon {
            @extend #{$class};
            .fa {
                line-height: $lh;
                float: right;
                padding: $btn-padding;
                margin: -$btn-padding;
                margin-left: $btn-padding;
                @if $btn-border {
                    border-left: $btn-border;
                }

            }
        }
	}
}
