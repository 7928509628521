// ==========================================================================
// Navigation
// ==========================================================================

$nav-main-height        : 4rem;
$nav-sm-height          : 4rem;


@include nav-main('.nav-main', $nav-main-height, true);

.nav-main__icon {
    left: auto;
    right: 15px;
}
.nav-main__brand {
    position: absolute;
    left: 15px;
}
.nav-main {
    position: fixed;
    background: $snow-white;
    box-shadow: inset 0 -3px darken($snow-white,15%);
    &__panel {
        max-width: 100vw;
        background: #101010;
        @include mq(md){
            margin-top: 0;
            top: $nav-main-height;
            overflow: visible;
        }
        @include mq(xl){
            background: none;
        }
    }
    &__primary {
        margin-top: calc( #{$nav-main-height} + #{$gutter-width} );
        //overflow: auto;
        height: calc(100vh - 4rem);
        li {
            a {
                display: block;
                width: 100%;
                font: 700 .875em $ff--secondary;
                text-transform: uppercase;
                line-height: $nav-main-height;
                padding: 0 1.25em;
                white-space: nowrap;
                text-align: left;
                box-shadow: inset 0 -3px #1e1e1e;
                transition: box-shadow .375s cubic-bezier(0, 0.5, 0.5, 1);
                color: $snow-white;
                background: #101010;
            }
            &:hover > a {
                box-shadow: inset 0 #{-$nav-main-height} #1e1e1e;
            }
            ul li a {
                background: lighten($darth-vader, 5%);
                padding: 0 1.25em 0 2.5em;
            }

        }
        @include mq(md){
            margin-top: 0;

        }
        @include mq(xl){
            float: right;
            height: auto;
            li {
                position: relative;
                float:left;
                a {
                    box-shadow: inset 0 -3px #e2e2e2;
                    color: color(greys, xdark);
                    background: none;
                }
                ul {
                    position: absolute;
                    top: $nav-main-height;
                    left: 0;
                    background: $snow-white;
                    overflow: hidden;
                    transition: transform 200ms ease, opacity 200ms ease;

                    opacity: 0;
                    transform-origin: top;
                    transform: scaleY(0);

                    li {
                        width: 100%;
                        a {
                            background: $snow-white;
                            padding: 0 1.25em;
                        }
                    }
                }
            }
            li:hover {
                >a {
                    box-shadow: inset 0 #{-$nav-main-height} #e2e2e2;
                }
                ul {
                    opacity: 1;
                    transform: scaleY(1);
                }
            }
        }
    }
    &__secondary {

        li.highlights {
            position: absolute;
            top: $gutter-width/2;;
            left: $gutter-width/2;
            width: calc( 50% - #{$gutter-width/2});

            &+li.highlights {
                left: auto;
                right: $gutter-width/2;
            }
            a {
                display: block;
                width: 100%;
                font: 700 .875em $ff--secondary;
                text-transform: uppercase;
                line-height: $nav-main-height;
                padding: 0 1.25em;
                white-space: nowrap;
                text-align: left;
                color: $snow-white;
                text-align: center;
                transition: box-shadow .375s cubic-bezier(0, 0.5, 0.5, 1);
            }
            &.primary a {
                background: color(brand,primary);
                box-shadow: inset 0 -3px darken(color(brand,primary),15%);

                &:hover {
                    box-shadow: inset 0 #{-$nav-main-height} darken(color(brand,primary),15%);
                }

            }
            &.secondary a {
                background: color(brand,secondary);
                box-shadow: inset 0 -3px darken(color(brand,secondary),15%);

                &:hover {
                    box-shadow: inset 0 #{-$nav-main-height} darken(color(brand,secondary),15%);
                }
            }
        }
        @include mq(md){
            position: fixed;
            top: 0;
            left: calc(100% - #{$nav-mobile-width});
            li.highlights {
                display: inline-block;
                float: left;
                position: static;
                width: auto;
            }
        }

        @include mq(xl){
            float: right;
            position: static;

            li.highlights {
                color: $snow-white;
                position: static;
                width: auto;
                float:left;
                a {
                    box-shadow: none;
                    color: $snow-white;
                }
            }



        }
    }
}

.main {
    padding-top: $nav-main-height;
}
