// ==========================================================================
// Team Members
// ==========================================================================

.team-member {
    &.header {
        text-align: center;
        @include mq(sm){
            text-align: left;
        }
        .profile-picture {
            border-radius: 50%;
            margin-top: $gutter-width;
            max-width: 140px;
            position: relative;
            @include mq(sm){
                border-radius: 0;
                max-width: 100%;
                position:absolute;
                bottom:0;
                right:52%;
                width: auto;
                max-height: 90%;
                z-index: 10;
            }
        }
        .emphasis {
            figure figcaption {
                text-align: center;
                float: none;
                width: 100%;
                @include mq(sm){
                    text-align: left;
                }
            }

            h1 {
                font-size: 2em;
                text-transform: uppercase;
                margin-top: 0;
                line-height: 1.1;
            }
            @include mq(sm){
                position:absolute;
                bottom:50%;
                left:52%;
                width: 48%;
                transform: translateY(50%);
                h1 {
                    font-size: 3.75em;
                }
            }
        }
    }
    .accreditations {
        img {
            max-width : 45%;
            display: block;
            float: left;
            &:nth-child(2n) {
                margin-left: 10%;
            }
        }
    }
}

.team-grid {
    @extend .npb;
    .member {
        @extend .col-r-12;
        @extend .col-xs-6;
        @extend .col-md-3;

        .square {
            position:relative;
            overflow:hidden;
            display: block;
            height: 100vw;

            @include mq(xs) {
                height: auto;
            }

            img {
                position: absolute;
                width: 100%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 0;
            }

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.5);
                z-index: -1;
            }

            &:before {
                display: inline-block;
                width: 3em;
                height: 3em;
                line-height: 3em;
                border-radius: 1.5em;
                text-align: center;
                border: 2px solid $snow-white;
                color: $snow-white;
                @extend .fa;
                @extend .fa-plus;
                font-size: 2.5em;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%,-50%,0);
                z-index: -1;
            }

            &:hover:after {
                z-index: 1;
            }
            &:hover:before {
                z-index: 3;
            }
        }

        &__caption {
            position:absolute;
            bottom: $gutter-width / 2;
            left: $gutter-width / 2;
            padding: $gutter-width / 2;
            text-transform:uppercase;
            color: $darth-vader;
            background: $snow-white;
            z-index: 2;
            max-width: calc(100% - #{$gutter-width});
            small {
                display: block;
                line-height: 1.2;
                margin-top: .5em;
            }
        }

        &:nth-child(even) {
            .member__caption {
                background: $darth-vader;
                color: $snow-white;
            }
        }
    }

    .member__category {
        @extend .member;
        background: $darth-vader;

        .square {
            &:before,
            &:after {
                display: none;
            }
            height: 50vw;
            @include mq(xs) {
                height: auto;
            }
        }

        &__caption {
            position:absolute;
            bottom: $gutter-width / 2;
            left: $gutter-width / 2;
            padding: $gutter-width / 2;
            text-transform:uppercase;
            color: $snow-white;
            font: 700 2em $ff--secondary;
        }
    }
}
