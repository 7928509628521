// ==========================================================================
// PDF banner Homepage
// ==========================================================================

.pdf-banner {
    background-color: $color--secondary;
    display: block;
    opacity: 1;
    position: relative;
    padding: 1em;
    border-radius: 10px;
    overflow: hidden;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    margin-top: 2em;

    @include mq(sm) {
        padding: 0.5em;
        margin-top: 0;
    }
    @include mq(md) {
        padding: 1.5em;
    }

    // Toggle this class " .pdf-banner--active" on div class "leading"
    &--active {
        margin-bottom: 1em;
    }

    .icon {
        border: 2px solid $white;
        margin-bottom: 0.5em;
        margin-top: 0.6em;
        background-position: center;
        @include mq(md) {
            margin-top: 0.5em;
        }
    }

    &__step {
        position: relative;
        transition:  $transition--normal ease;
        z-index: 2;
        width: 100%;
        height: 100%;
        transform: translate3d(0, 100%, 0);
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color--secondary;

        .btn {
            margin-bottom: 1em;
            padding: 0.7em;
            @include mq(md) {
                padding: 0.925rem 1.75rem;
            }
        }

        &.step-first {
            z-index: 3;
            transform: translate3d(0, 0%, 0);
            .icon {
                background:url(../../dist/images/ic-pdf-logo-board.png) no-repeat;
                background-position: center;
            }
        }
        &.step-second {
            z-index: 4;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1.5em;
            .pdf-banner__title {
                margin-bottom: 1em;
            }
            form {
                @include mq(sm) {
                    margin-top: 0;
                    margin-bottom: 0.8em;
                }
                @include mq(md) {
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
            }
        }

        &.step-third {
            z-index: 6;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1.5em;
            .icon {
                background:url(../../dist/images/ic-pdf-logo-check.png) no-repeat;
                background-position: center;
            }
        }
        &.is-active {
            transform: translate3d(0, 0, 0);
        }
    }

    &__title {
        font: 400 1.125em "Roboto Condensed", Helvetica, sans-serif;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 1.5em;
        margin-top: 0.4em;
        width: 100%;
    }

    form {
        margin-top: 1em;
        margin-bottom: 1em;

        .checkbox {
            padding-top: 1em;
            margin-right: 0;
            label {
                color: $white;
                font-size: em(12px);
                padding-left: 1.3em;
                line-height: inherit;
                min-height: inherit;
                margin-bottom: 0;

                &:before {
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    left: -10px;
                }

                &:after {
                    top: -5px;
                    left: -14px;
                }
            }
        }
    }

    .parsley-errors-list {
        display: none;
    }

    input[type="email"].parsley-error {
      border: 1px solid red;
    }

}
