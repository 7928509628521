
.accordion-tabs {

    margin: 0;
    padding: 0;

    .tab {
        list-style: none;
        position: relative;
        &:before {
            display: none;
        }
    }

    .tab-link {
        display: inline-block;
        margin-bottom: 0;


        &.is-active {
            background: $darth-vader;
            box-shadow: none;
            border-radius: 25px 25px 0 0;
        }
    }

    .tab-content {
        display: none;
        background: $darth-vader;
        color: $snow-white;
        position: relative;
        padding-top: $gutter-width / 2;
        padding-bottom: $gutter-width / 2;

        &.is-open {
            display: block;
        }
    }

    @include mq(md) {
        .tab {
            display: inline;
        }
        .tab-link {
            margin-bottom: 1.5em;

            &.is-active {
                border-radius: 50px;
                &:after {
                    position: absolute;
                    bottom: -3.5em;
                    left: calc(50% - 1em);
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 1em solid transparent;
                    border-right: 1em solid transparent;
                    border-bottom: 1em solid $darth-vader;
                }
            }
        }
        .tab-content {
            float: left;
            &.is-open {
                display: table;
                width: 100%;

                &:before {
                    display: table-cell;
                    content:"";
                    height:100%;
                    position: absolute;
                    top:0;
                    left:-100%;
                    width:100%;
                    background: $darth-vader;
                }

                &:after {
                    display: table-cell;
                    content:"";
                    height:100%;
                    position: absolute;
                    top:0;
                    right:-100%;
                    width:100%;
                    background: $darth-vader;
                }
            }
        }
    }
}
