.checkout {
    font-family: $ff__secondary;

    &__cancel {
        margin-top: em(40px, 14px);
        padding: em(25px 10px, 14px);
        border-top: 1px solid color(greys, base);
        font-size: em(14px);
        font-weight: 300;
        color: color(greys, dark);
        text-align: center;

        a:hover {
            color: $color--secondary;
        }
    }

    &__buttons {
        text-align: center;
        margin-top: em(20px);

        a:not(.btn--primary):not(.btn--secondary) {
            display: block;
            margin-top: 1em;
            font-family: $ff__secondary;
            font-size: .875em;
            color: $color--secondary;
        }

        .btn--primary {
            margin: 0 10px 10px;
        }
    }

    &__questions {
        margin-top: em(60px);
        padding-top: em(20px);
        border-top: 1px solid color(greys, base);

        a {
            color: $color--secondary;
        }
    }

    &__checkbox {
        text-align: left;

        input[type="checkbox"] {
            margin: 6px 10px 0 0;

            & + label {
                display: inline;

                a {
                    font-weight: bold;
                    color: $color--primary;
                }
            }
        }
    }

    &__informations {
        font-family: $ff__primary;
        font-size: 1.125em;

        th,
        td {
            padding: .75em 1em;
        }

        th {
            padding-left: 0;
        }
    }

    .entry-box__title {
        p {
            margin: 10px auto 0 auto;
        }

        small {
            font-size: 90%;
        }
    }

    .entry-box__title__inner {
        display: block;
        font-size: 1.25em;
        font-weight: bold;
        color: $color--secondary;
    }

    .form-item--wide {
        text-align: left;
    }

    ol {
        counter-reset: item;
        text-align: left;

        li {
            list-style: none;
            margin-bottom: 10px;
            font-weight: bold;

            &:before {
                content: counters(item, '.');
                counter-increment: item;
                display: inline-block;
                width: 21px;
                height: 21px;
                line-height: 21px;
                margin-right: 12px;
                border: 1px solid $color--secondary;
                border-radius: 100%;
                font-size: 15px;
                font-weight: bold;
                color: $color--secondary;
                text-align: center;
            }

            strong {
                color: $color--secondary;
            }

            ol {
                counter-reset: item;
            }
        }
    }

    &__summary {
        th,
        td {
            border-top: 0;
            padding-top: 0;
            padding-bottom: 1em;
            font-weight: 400;
        }

        .total {
            th,
            td {
                padding-top: 1em;
                border-top: 1px solid color(greys, base);
            }
        }
    }

    .fa-question-circle {
        font-size: .8125em;
        color: $color--secondary;
    }

    .tooltip__flip {
        font-size: 1rem;
        color: $color--secondary;
    }

    [gr-password-strengh] {
        margin-top: 1em;

        &:before {
            content: '8 caractères minimum';
            display: block;
            color: color('greys', 'light');
        }

        span {
            position: relative;
            display: block;
            height: 8px;
            width: 32%;
            background-color: color('greys', 'light');

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                height: 8px;
                width: 100%;
                margin-left: 5px;
                background-color: color('greys', 'light');
            }

            &:before {
                left: 100%;
            }

            &:after {
                left: calc( 200% + 5px );
            }
        }
    }

    [gr-password-strengh="0"] {
        margin: 20px 0;

        span {
            background-color: red;
        }

        &:before {
            content: 'Faible';
            color: red;
        }
    }

    [gr-password-strengh="1"] {
        margin: 20px 0;

        span,
        span:before {
            background-color: orange;
        }

        &:before {
            content: 'Moyen';
            color: orange;
        }
    }

    [gr-password-strengh="2"] {
        margin: 20px 0;

        span,
        span:before,
        span:after {
            background-color: green;
        }

        &:before {
            content: 'Fort';
            color: green;
        }
    }

    &--login,
    &--create,
    &--forgot,
    &--payment,
    &--modify {
        @extend .checkout;
    }

    &--modify {
        .checkout__buttons {
            margin-top: em(40px);
        }
    }

    &--payment {
        .cvc,
        .tooltip {
            position: static;
        }
    }

    &--confirm {
        font-family: $ff__primary;

        .checkout__title {
            margin: 1.25rem 1.5rem;
            font-size: 1.3125em;
            font-weight: bold;
            color: $color--primary;
        }

        .checkout__buttons {
            margin-top: 2rem;
        }
    }

    @include mq(sm) {
        .form-item--wide {
            text-align: left;

            label {
                display: inline-block;
                padding-right: .625em;
                text-align: right;
                line-height: 54px;
                vertical-align: middle;

                span:not(.fa) {
                    display: inline-block;
                    line-height: 1.2;
                }
            }
        }

        [gr-password-strengh] {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin-top: 0;
            width: 12.5em;
        }

        [gr-password-strengh="0"],
        [gr-password-strengh="1"],
        [gr-password-strengh="2"] {
            margin: 0;
        }

        &__checkbox,
        &__payments {
            input[type="checkbox"],
            input[type="radio"] {
                & + label {
                    width: auto;
                    padding-right: 0;
                    line-height: 27px;
                    text-align: left;
                }
            }
        }

        &__expiration,
        &__payments {
            display: inline-block;
            width: calc( 100% - 13.5em );
        }

        &__expiration {
            .form-item--wide {
                float: left;
                width: 33%;
                padding-right: 15px;

                &.cvc {
                    width: 34%;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        &__payments {
            .radio-container {
                float: left;
                padding-right: 15px;
            }

            label {
                margin-top: .5em;
            }
        }

        &__informations {
            th {
                width: 220px;
            }
        }

        &--login {
            .form-item--wide {
                label {
                    width: 7.5em;

                    & + input[type="text"],
                    & + input[type="password"],
                    & + input[type="email"] {
                        width: calc( 100% - 7.5em );
                    }
                }
            }
        }

        &--modify {
            .form-item--wide {
                label {
                    width: 8.5em;

                    & + input[type="text"],
                    & + input[type="password"],
                    & + input[type="email"] {
                        width: calc( 100% - 8.5em );
                    }
                }
            }

            [gr-password-strengh] {
                position: static;
                transform: none;
                margin: 1em 0 0 8.5em;
            }
        }

        &--forgot {
            .form-item--wide {
                label {
                    width: 5em;

                    & + input[type="text"],
                    & + input[type="password"],
                    & + input[type="email"] {
                        width: calc( 100% - 5em );
                    }
                }
            }
        }

        &--create {
            .entry-box__wrapper {
                .form-item--wide {
                    padding-right: 13.5em;
                }
            }
        }

        &--create,
        &--payment {
            .form-item--wide {
                label {
                    width: 13.5em;

                    & + input[type="text"],
                    & + input[type="password"],
                    & + input[type="email"] {
                        width: calc( 100% - 13.5em );
                    }
                }
            }
        }

        &--payment {
            .cvc {
                label {
                    width: 4.5em;

                    & + input[type="text"] {
                        width: calc( 100% - 4.5em );
                    }
                }
            }
        }
    }
}

.login-popup {
    .entry-box {
        border-bottom: 0;
    }
}
