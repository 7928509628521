// ==========================================================================
// Breadcrumb
// ==========================================================================

@mixin breadcrumb($class: '.breadcrumb', $separator: $fa-var-angle-right) {

	#{$class} {
		list-style: none;
		li {
	        display: inline-block;
	        &:not(:last-child):after {
	            margin: 0 .2em;
	            display: inline-block;
	            font-family: $ff__fa;
	            content: $separator;
	        }
	        &:last-child {
	            a {
	                cursor: default;
	            }
	        }
	    }
	}
}
