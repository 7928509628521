.categories-wrapper {
    background: $darth-vader;
    padding: 1.5em 0 0;
    &.owl-carousel {
        text-align: center;
    }
    a {
        margin-bottom:calc(1.5em + 3px);
        width: 200px;
        text-align: center;
        display: inline-block;
        height: 60px;

        position:relative;
        span {
            position: absolute;
            display: inline-block;
            top: 50%;
            left:0;
            transform: translateY(-50%);
            white-space: normal;
            width:100%;
            padding: 0.5rem 1rem;
        }
        &+a {
            margin-left:.5em;
        }
        &.active {
            box-shadow: 0 3px darken(color(brand,primary),15%);
            background-color: color(brand,primary);
            &:hover {
                background-color: darken(color(brand,primary),15%);
            }
        }
    }

    .slick-prev {
        left: - $gutter-width;
        top: calc(50% - .75em);
    }
    .slick-next {
        right: - $gutter-width;
        top: calc(50% - .75em);
    }
}
