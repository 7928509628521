/**
 * @name: temoignages
 */
.temoignage-title {
    font-size: 2em;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    padding-bottom: 1em;
}
.testimonials .owl-carousel .owl-item img {
    width: auto;
}
.testimonials {
    .owl-carousel {
        margin-bottom: 0;
    }
    .testimonial {
        position: relative;
        overflow: visible;
        margin-bottom: 30px;
        &.owl-current .testimonial__footer:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid color(greys, xlight);
            position: absolute;
            bottom: -2em;
            left: 50%;
            margin-left: -20px;
        }
    }
}
.banner--testimonials {
    .lead__title {
        color: $white;
    }
    .testimonial {
        margin-bottom: 2.3125em
    }
}
.testimonial__content {
    .entry-box__header { margin: -2.3125em -2.3125em 0; }
    .entry-box__footer { margin: 0 -2.3125em -2.3125em; }
}
