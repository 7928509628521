// ==========================================================================
// Global
// ==========================================================================

html {
	font-size: $fs;
		-ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: $ff;
	font-size: $fs;
	color: $fc;
	line-height: $lh;
}

hr {
	border-top: #{$border};
	margin: 0;
    &.hr--large {
        margin: $margin 0;
    }
}

a {
    cursor: pointer;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    max-width: 100%;
    width: 100%;
    border-bottom: $border;
    margin-bottom: $margin;
    th, td {
        padding: $padding / 2 $padding;
        vertical-align: top;
        border-top: $border;
    }
    th {
        font-weight: 700;
        padding-left: 0;
        text-align: left;
        text-transform: uppercase;
        width: 160px;
    }
}

.tac {
    text-align: center;
}

.iframe-wrapper iframe{
    pointer-events: none;
}

.pika-single {
    width: 260px;
    margin-left: 15px;
}

.lead {

    display: block;
    font: 300 1.5em / 2rem $ff--primary;

    .lead__title {
        margin-bottom: .5em;
        text-transform: uppercase;
        font: 700 1.33em $ff--secondary;
    }
    span {
        color: color(brand, secondary);
        font-weight: 600;
    }
    &--center {
        text-align: center;
    }
    .btn--primary {
        margin-top: .5em;
    }
}

.tagline {

    display: block;

    &__heading {
        font: $h2 $ff--secondary;
        text-transform: uppercase;
    }
    span {
        color: color(brand, secondary);
        font-weight: 600;
    }
    &--center {
        text-align: center;
    }
}

.iframe-wrapper {
    margin: $margin 0;
    @include keepRatio('16/9');
}

.typography ul.timeline {
    position: relative;
    &:before {
        content: "";
        width: 1px;
        height: calc(100% - 1.5em);
        position: absolute;
        top: .75em;
        background: color(brand, secondary);
        left: .25em;
    }
    li {
        &:before {
            color: color(brand, secondary);
           -webkit-text-fill-color: $snow-white; /* Will override color (regardless of order) */
           -webkit-text-stroke-width: 1px;
           -webkit-text-stroke-color: color(brand, secondary);
            float: left;
        }
        p {
            margin-left: 1.5em;
        }
    }
}


.owl-nav {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
}
.owl {
    margin: 2rem 0 0;
    padding: 0;
    figure {
        @include keepRatio('16/9');
    }
    .iframe-wrapper {
        margin: 0;
    }
    .owl-nav {
        bottom: 0;
        margin: auto;
        .owl-prev,
        .owl-next {
            top:50%;
            transform: translateY(-50%);
        }
    }
}
.owl-item {
    text-align: center;
}
.owl-prev,
.owl-next {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 63px;

    position:absolute;
    padding: 0;
    height: 63px;
    width: 3em;
    text-align: center;
    left: $gutter-width / 2;
    z-index: 10;
    .categories-wrapper & {
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 30%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 30%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
    }
    .testimonials & {
        margin-top: $gutter-width;
    }

    &:before {
        display: none;
    }
    &:after {
        @extend .fa;
        content: $fa-var-chevron-left;
        color: rgba(255,255,255,.75);
        position: static;
        margin-top: 0;
        font-size: 3em;
        line-height: 63px;
    }
    &:hover:after {
        color: $snow-white;
    }
    &.disabled {
        display:none;
    }
}
.owl-next {
    left: auto;
    right: $gutter-width / 2;
    .categories-wrapper & {
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjcwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 70%,rgba(0,0,0,1) 100%);
        background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 70%,rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
    }
    &:after {
        content: $fa-var-chevron-right;
    }
}
.owl-dots {
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: $gutter-width;
    .owl-dot {
        display: inline-block;
        margin: 0 5px;
    }
    .owl-dot:before {
        @extend .fa;
        @extend .fa-circle;
        font-size: 1rem;
        color: color(greys, dark);
       -webkit-text-fill-color: color(greys, primary); /* Will override color (regardless of order) */
       -webkit-text-stroke-width: 1px;
       -webkit-text-stroke-color: $snow-white;
       opacity : 1;
    }
    .owl-dot.active:before {
        color: color(brand, secondary);
       -webkit-text-fill-color: color(brand, primary); /* Will override color (regardless of order) */
    }
}

.error-alert {
    text-align: center;
    background: $color--error;
    box-shadow: inset 0 -3px darken($color--error,15%);
    color: $snow-white;
    padding: 1em 0;
}
.success-alert {
    text-align: center;
    background: color(brand, primary);
    box-shadow: inset 0 -3px darken(color(brand, primary),15%);
    color: $snow-white;
    padding: 1em 0;
}

.pull-md-right {
    margin-bottom: 1.5em!important;
    @include mq(md) {
        float: right;
    }
}
