// ========================================================
// Layouts
// ========================================================

@include grid('.container', $grid-columns, $gutter-width, $container-width, false);
@include grid('.container--wide', $grid-columns, 0, false, false);

@include banner('.banner', auto);

.nav-main-handler {
	@extend #{$nav-mobile-animation};
}

@import "header";
@import "footer";

section {
    padding: 2.3125em 0;
    position: relative;
    @include clearfix;

    &.npt {
        padding-top: 0;
    }
    &.npb {
        padding-bottom: 0;
    }

    .leading {
        @extend .col-xs-12;
        @extend .col-sm-10;
        @extend .col-sm-offset-1;
        @extend .col-md-8;
        @extend .col-md-offset-2;
    }

    &.highlight {
        background: color(greys, xlight);
        .tagline {
            padding: $padding*2 0 $padding*1.5;
            text-align: center;
        }
		&--color-secondary {
			background: $color--secondary;
		}
    }

    &.alternate {
        &:nth-child(even){
            background: color(greys, xlight);
        }
    }
		&.alternate--reverse {
				&:nth-child(odd){
						background: color(greys, xlight);
				}
		}

    &.section-no-overflow {
        overflow: visible;
    }
		&.section-no-overflow--alt {
				overflow: visible;
				padding-bottom: 0;
		}



    &.advises {
        .advise {
            @extend .col-xs-12;
            @extend .col-sm-6;
            @extend .col-md-4;
            /*
            &:not(:nth-child(1)),
            &:not(:nth-child(10)) {
                @extend .col-lg-3;
            }
            &:nth-child(1),
            &:nth-child(10) {
                @extend .col-lg-6;
            }
            &:nth-child(10) {
                float:right!important;
            }
            */
            &.featured:after {
                position: absolute;
                left: $gutter-width / 2;
                top: 2.5em;
                content: attr(data-tag);
                background: color(brand,secondary);
                color:$snow-white;
                box-shadow: 0 3px darken(color(brand,secondary),15%);
                padding: .5em 1em;
                text-transform: uppercase;
                font-family: $ff--secondary;
            }

			.entry-box {
				font-family: $ff__secondary;
				text-align: left;
				padding-left: $gutter-width / 2;
				padding-right: $gutter-width / 2;
				@include mq(sm) {
					padding-left: $gutter-width;
					padding-right: $gutter-width;
				}
			}

			.entry-box__figure {
				margin-bottom: 0;
				display: block;
				@include mq(lg) {
					&::before {
						content: "";
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: transparent;
						background: url(/dist/images/svg/ic-cible.svg);
						background-repeat: no-repeat;
						background-position: center;
						background-size: 80px auto;
						z-index: 999;
						transition: background-color $transition--fast ease, transform $transition--fast ease, opacity $transition--fast ease;
						opacity: 0;
						will-change: opacity;
					}

					&:hover::before {
						background-color: rgba($color--primary, .8);
						transform: scale(1.1);
						opacity: 1;
					}
				}
			}

			.entry-box__category {
				font-size: 1rem;
				color: white;
				padding-left: $gutter-width / 2;
				padding-right: $gutter-width / 2;
				padding-top: $gutter-width / 3;
				padding-bottom: $gutter-width / 3;
				background-color: $color--primary;
				margin-left: -$gutter-width / 2;
				margin-right: -$gutter-width / 2;
				text-transform: uppercase;
				font-weight: 400;
				margin-bottom: $gutter-width;
				@include mq(sm) {
					padding-left: $gutter-width;
					padding-right: $gutter-width;
					margin-left: -$gutter-width;
					margin-right: -$gutter-width;
				}

				& + .entry-box__title {
					margin-top: 0;
				}
			}

			.entry-box__title {
				padding: 0;
				font-size: 1.8rem;
				text-transform: uppercase;
				line-height: 32px;
				font-weight: 400;
				margin-bottom: $gutter-width / 2;
				margin-top: $gutter-width;
			}

			.entry-box__author {
				text-transform: uppercase;
				font-size: 1rem;
				margin-bottom: $gutter-width / 2.5;
				font-weight: 400;
				strong {
					color: $color--primary;
				}
			}

			.entry-box__desc {
				font-size: 1rem;
				line-height: 28px;
				margin-bottom: $gutter-width;
			}

			.entry-box__link {
				color: $color--primary;
				text-align: center;
				text-transform: uppercase;
				display: inline-block;
				width: 100%;
				transition: color $transition--fast ease;
				@include mq(sm) {
					text-align: right;
				}

				@include mq(lg) {
					&:hover {
						color: #6e962c;
					}
				}
			}


            @include mq(lg){
                // .entry-box__title {
                //     height: 5.25em;
				//
                // }

                figure {
                    @include keepRatio('16/9');
                }

                /*
                &:not(:nth-child(1)),
                &:not(:nth-child(10)) {
                    figure {
                        @include keepRatio('16/9');
                    }
                }

                &:nth-child(1),
                &:nth-child(10) {
                    figure {
                        @include keepRatio();
                    }
                }
                */
            }
        }

		.emphasis {
			border-top: $border;
			margin-top: $gutter-width;
			padding-top: $gutter-width;
		}
    }
    &.contact {
        .iframe-wrapper {
            @include keepRatio('16/9');
            margin: 2em 0;
        }
        &.typography {
            ul:not(.parsley-errors-list) li {
                font: 300 1em / 1.25 $ff--secondary;
                margin-left: 1em;
            }
            h1 {
                text-transform: uppercase;
                margin-top: 5px;
            }
            h2 {
                strong {
                    text-transform: uppercase;
                }
                small {
                    vertical-align: baseline;
                    text-transform: none;
                    line-height: 1.2;
                    display: block;
                }
            }
            .gmap {
                @extend .alt-link;
                font: 300 1em / 2.5 $ff--secondary;
            }
        }
    }
    &.coach-de-coach {
        min-height: 32vw;
        font-size: .75em;
        background: url(/dist/images/bandeau-equipe.jpg) center center no-repeat;
        @include mq(md){
            font-size: 1em;
        }
        figure {
            &:before {
                content: '';
                display: block;
                width: 100%;
                padding-top: 32vw;
            }
            img {
                display: none;
                @include mq(sm){
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: auto;
                    transform: translate3d(-50%,-50%,0);
                    width: 100%;
                    height: auto;
                }
            }
        }
        .banner__inner .row>div{
            padding: 1.5em 0;
            @include mq(sm){
                text-align: left;
                padding: 1.5em 0 1.5em 2em;
                border-left: 1px solid color(greys, xdark);
            }
        }
    }
}

.newsletter {
    border-top: $border;
    &--noborder {
        @extend .newsletter;
        border-top: 0;
    }
    .tagline {
        @extend .col-xs-12;
        @extend .col-sm-6;
        padding-top: 3px;
    }
    .input-with-button {
        @extend .col-xs-12;
        @extend .col-sm-6;
        position: relative;
        text-align: right;
        margin-top: 1em;

        input[type=email] {
            width: 100%;
            float: right;
            padding-right: 188px;
        }
        .btn--primary {
            position: absolute;
            top: 0;
            right: $gutter-width / 2;
        }
        @include mq(sm) {
            margin-top: 0;
        }
    }
}

.home__ctas {
    padding-top: 2.3125em;
}
.home__cta {
    @include mq(sm){
        float:left;
        .off-1 & {
            width: 80%;
            margin-left: 20%;
        }
        .off-2 & {
            width: 50%;
        }
        .off-3 & {
            width: calc(100% / 3);
        }
        .off-4 & {
            width: 50%;
        }
        .off-5 & {
            width: 50%;
            &:nth-child(5) { margin-left: 25%; }
        }
    }
    @include mq(md){
        .off-1 & {
            width: 20%;
            margin-left: 40%;
        }
        .off-2 & {
            width: 20%;
            &:nth-child(1) { margin-left: 30%; }
        }
        .off-3 & {
            width: 20%;
            &:nth-child(1) { margin-left: 20%; }
        }
        .off-4 & {
            width: 20%;
            &:nth-child(1) { margin-left: 10%; }
        }
        .off-5 & {
            width: 20%;
            &:nth-child(5) { margin-left: 0; }
        }
    }
}

.formation__cta {
    @include mq(sm){
        float:left;
        .formations__ctas & {
            width: 50%;
        }
        .off-1 & {
            width: 80%;
            margin-left: 20%;
        }
        .off-2 & {
            width: 50%;
        }
        .off-3 & {
            width: calc(100% / 3);
        }
    }
    @include mq(md){
        .formations__ctas & {
            width: 25%;
        }
        .off-1 & {
            width: 40%;
            margin-left: 30%;
        }
        .off-2 & {
            width: 40%;
            &:nth-child(1) { margin-left: 10%; }
        }
        .off-3 & {
            width: 30%;
            &:nth-child(1) { margin-left: 5%; }
        }
    }
}

.mfp-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.25);
    -webkit-backdrop-filter: blur(5px);

}
.mfp-content {
    background: $snow-white;
    max-width: 900px;
    padding: 2em;
    .center {
        text-align: center;
    }
}
.main .mfp-content {
    display: block;
}

@import "student-area";
@import "checkout";
@import "formations";
