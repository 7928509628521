// ========================================================
// Student Area
// ========================================================

.student {

    &.header figure:before {
        padding-top: 20em;
    }

    @include mq(sm) {
        &.header {
            height: 20em;
        }
    }

    &.categories-wrapper {
        a.active {
            box-shadow: 0 3px darken(color(brand,secondary),15%);
            background-color: color(brand,secondary);
            &:hover {
                background-color: darken(color(brand,secondary),15%);
            }
        }
    }

    .entry-box {
        background: $snow-white;
        box-shadow: 0 3px color(greys, base);
        text-align: left;
        margin: 1em 0 1.125em;
        -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
        padding: 1em;

        &__title {
            display: block;
            margin-bottom: 1em;
            width: 100%;
            font: 700 1.125em $ff--secondary;
            text-transform: uppercase;
        }
    }

    .formation {
        margin: 1rem 0;
        position:relative;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            border-radius: 6px;
            width: 12px;
            height: 100%;
            background: color(greys, dark);
        }
        &--future:before {
            background: color(brand, primary);
        }
        &--current:before {
            background: color(brand, secondary);
        }
        &--disabled {
            opacity: .3;
        }
        figure {
            overflow: hidden;
            display: table;
            width: 100%;
            >img {
                display: table-cell;
                border-radius: 50%;
                float: left;
                width: 120px;
            }
            picture {
                display: table-cell;
                border-radius: 50%;
                float: left;
                width: 120px;
                position: relative;
                margin-left: $gutter-width;
                overflow: hidden;

                &:before{
                    content:'';
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                }

                >img {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin: auto;
                    min-width: 100%;
                    height: 100%;
                    -webkit-filter: grayscale(0);
                    filter: none;
                }
            }
            figcaption {
                display: table-cell;
                padding: 1rem 0 1rem $gutter-width;
                text-transform: uppercase;
                position: relative;
                vertical-align: middle;
                text-align: left;
                width: 100%;
                p {
                    margin: 0;
                    text-transform: none;
                }
            }
        }
        &__date {
            color: color(greys,dark);
            font: 700 1em $ff--secondary;
            margin-bottom: .5em;
        }
        &--future .formation__date {
            color: color(brand, primary);
        }
        &--current .formation__date {
            color: color(brand, secondary);
        }

        &__title {
            display: block;
            font: 1.375em / 1 $ff--secondary;
            margin: .5rem 0;
        }
        &__infos {
            font: 400 1em $ff--secondary;
            span {
                display: inline-block;
                vertical-align: baseline;
                &+span {
                    margin-left: 1em;
                }
            }
            .btn--secondary {
                padding: .5em 1em;
            }
        }
        &__notes {
            display: table;
            float: right;
            margin-left: 1em;
            margin-top: .75em;
            font: 400 .75em / 1 $ff--secondary;
            span+span {
                margin-left: .5em;
                font-weight: 700;
            }
            span:nth-child(2){
                font-size: 2.25em;
            }
            &.soon {
                span+span {
                    color: #fad718;
                }
            }
        }
        &+.formation {
            // border-top: $border;
            // padding: 1rem 0;
            // &:before {
            //     top: 1rem;
            // }
        }
        &__description {
            padding: 0 0 4em $gutter-width;
        }
        &__description--offset {
            padding-bottom: 4em;
            @include mq(sm) {
                padding: 0 0 4em 180px;
            }
        }
        &--page {
            font-size: 1.5em;
            margin: 0;
            @extend .formation--current;

            &.entry-box {
                margin-top: $margin;
            }

            figure figcaption {
                padding: 0 0 0 $gutter-width;
            }
            .formation__title {
                text-transform: uppercase;
                font: 700 1em / 1 $ff--secondary;
            }
            .formation__notes {
                display: table;
                float: none;
                margin: 1em 0;
                font: 400 .65em / 1 $ff--secondary;
                span {
                    display: inline-block;
                    text-transform: uppercase;
                    &:first-child {
                        //display: block;
                        //margin-bottom: 10px;
                    }
                    &+span {
                        margin-left: .5em;
                        font-weight: 700;
                    }
                }
                span:nth-child(2){
                    font-size: 2.25em;
                }
                &.soon {
                    span+span {
                        color: #fad718;
                    }
                }
            }
            p {
                font-size: 1rem;
            }
            .social-icons {
                margin-top : 0;
            }

        }
        .social-icons {
            font-size: 1rem;
        }
    }
}
