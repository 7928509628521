// ==========================================================================
// Main
// ==========================================================================

// “One file to rule them all,
//  One file to find them,
//  One file to bring them all,
//  And in the Sass way merge them.”

@import "base/variables";
@import "base/maps";
@import "fonts/fonts";
@import "utilities/utilities";

// Bower imports goes here
@import "../vendor/sanitize-css/sanitize";
@import "../vendor/components-font-awesome/scss/font-awesome";
@import "../vendor/magnific-popup/src/css/main";
@import "../vendor/pikaday/scss/pikaday.scss";
@import "../vendor/owl.carousel/src/scss/owl.carousel.scss";
@import "../vendor/owl.carousel/src/scss/owl.theme.default.scss";

@import "base/base";

@import "components/components";

@import "layouts/layouts";


$swatch-width: 2em;

.color-swatches {
    overflow:hidden;
    .color-swatch {
	    float: left;
	    width: $swatch-width*2;
	    height: $swatch-width*2;
	    line-height: $swatch-width*2;
	    margin: 0 5px 10px;
	    border-radius: 50%;
	    text-align: center;
	    color: $snow-white;
	    @include mq(sm) {
	    	width: $swatch-width*3;
        	height: $swatch-width*3;
        	line-height: $swatch-width*3;
	    }
	    span {
	    	display: none;
	    }
	}
	&--small {
		@extend .color-swatches;
		.color-swatch {
			width: $swatch-width;
	    	height: $swatch-width;
	    	line-height: $swatch-width;
			@include mq(sm) {
		    	width: $swatch-width*2;
	        	height: $swatch-width*2;
	        	line-height: $swatch-width*2;
		    }
		}
	}
}

@each $name, $color-swatch in map-get($colors, brand) {
	.cs-brand-#{$name} {
		background-color: $color-swatch;
		&:before {
			content: "#{$color-swatch}";
			font-size: .75em;
		}
	}
}
@each $name, $color-swatch in map-get($colors, greys) {
	.cs-grey-#{$name} {
		background-color: $color-swatch;
		&:before {
			content: "#{$color-swatch}";
			font-size: .75em;
		}
	}
}


