// ==========================================================================
// Typography
// ==========================================================================

.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $ff--secondary;
	line-height: $lh;
    font-weight: 700;
}

.h1 { font-size: $h1; text-transform: uppercase; }
.h2 { font-size: $h2; text-transform: uppercase; }
.h3 { font-size: $h3; }
.h4 { font-size: $h4; }
.h5 { font-size: $h5; }
.h6 { font-size: $h6; }

.h3 {
	.fa {
		margin-left: 10px;
		line-height: 26px;
		font-size: 16px;
		color: $color--secondary;
	}
}

.link {
	text-decoration: none;
    transition: color $transition--normal;
    color: color(brand,primary);
    &:hover {
        color: darken(color(brand,primary),15%);
    }

    &:focus {}
    &:visited {}
    &[disabled] {}

    &--examen {
        color: color(brand,secondary);

        &:before {
            position: relative;
            @extend .fa;
            @extend .fa-angle-right:before;
            left: 0;
            color: color(brand,secondary);
            margin-top: 2px;
            margin-right: 10px;
            transition: left .3s;
        }

        &:hover:before {
            left: 5px;
        }
    }
}

.alt-link {
    @extend .link;
    color: color(brand,secondary);
    &:hover {
        color: darken(color(brand,secondary),15%);
    }
}

.strong {
	font-weight: 600;
}

.italic {
	font-style: italic;
}

.unordered-list,
.ordered-list {
    list-style: none;
    margin-bottom: $margin/2;
    li {
        position: relative;
        &+li {
            margin-top: $margin/2;
        }
        ul, ol {
            margin-top: $margin/2;
        }
        li {
            padding-left: $padding;
        }
    }
}

.unordered-list {
    li {
        &:before {
            @extend .fa;
            @extend .fa-circle;
            font-size: .75em;
            color: $color--primary;
            position: relative;
            top: .375em;
            margin-right: .5em;
        }
    }
}

.ordered-list {
    counter-reset: item;

    li {
        &:before {
            content: counters(item, ".")" - ";
            counter-increment: item;
            color: $color--secondary;
        }
        li {
            counter-reset: item;
        }
    }
}

.address {
    font-size: 1em;
    font-style: normal;
    line-height: 1.2;
    text-transform: uppercase;
    color: color(brand,primary);
}

.typography {
    h1, .h1 { @extend .h1; margin: 2rem 0 $margin; }
    h2, .h2 { @extend .h2; margin: 2rem 0 $margin; }
    h3, .h3 { @extend .h3; margin: 2rem 0 $margin; }
    h4, .h4 { @extend .h4; margin: $margin 0 $margin/2 }
    h5, .h5 { @extend .h5; margin: $margin 0 $margin/2 }
    h6, .h6 { @extend .h6; margin: $margin 0 $margin/2 }
	p {  margin-bottom: $margin; }
	p a { @extend .link; }
	strong, b { @extend .strong; }
	em, i { @extend .italic; }
	ul { @extend .unordered-list; }
	ol { @extend .ordered-list; }
	address { @extend .address; }
    hr { margin: 2*$margin 0 $margin; }
    img { max-width: 100%; }
    .container {
        margin-top: - 2rem;
    }
    .padded {
        margin-top: 2rem;
    }
}
