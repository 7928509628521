// ==========================================================================
// Forms
// ==========================================================================

$form-border        : $border  !default;
$form-radius        : $radius  !default;
$form-margin        : $margin  !default;
$form-padding       : $padding !default;

$form-size          : 54px !default;
$radio-size         : 27px !default;

$text-inputs-list: (
      'input[type="color"]',
      'input[type="date"]',
      'input[type="datetime"]',
      'input[type="datetime-local"]',
      'input[type="email"]',
      'input[type="month"]',
      'input[type="number"]',
      'input[type="password"]',
      'input[type="search"]',
      'input[type="tel"]',
      'input[type="text"]',
      'input[type="time"]',
      'input[type="url"]',
      'input[type="week"]',
      'textarea'
);

fieldset {
    legend {}
}

:focus {
    outline-style: none;
    box-shadow: none;
    //border: 0;
}

.form-item {
    position: relative;
    margin-bottom: $form-padding !important;
    //margin-top: 1.875em;
    label {
        line-height: $form-size;
    }
    &--wide {
        label {
            line-height: 1.2;
        }
        @extend .form-item;
        width: 100%;
        label,
        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="phone"],
        input[type="search"],
        .select {
            width: 100%;
        }
    }

}


// Input
// --------------------------------------------------
// @example html - Usage
//   <div class="form-item--wide">
//     <input type="text" id="" value="" placeholder="Example of Text Input">
//     <label for="">Name</label>
//   </div>
//
// The value attribute is required

@each $input in $text-inputs-list {

    #{$input} {
        @include placeholder {
            opacity: 1;
            transition: opacity .3s;
        }
        background: $snow-white;
        border: $form-border;
        border-radius:  $form-radius;
        padding: 0 $form-padding;
        height: $form-size;
        line-height: $form-size;
        width: 100%;
        max-width: 100%;

        .input--right-icon-wrapper & {
            & ~ .input--right-icon {
                line-height: inherit;
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 $form-padding;
                height: $form-size;
                line-height: $form-size;
                margin-left: $form-padding;
                @if $form-border {
                    border-left: $form-border;
                }
            }
        }

        &:focus {
            border-color: $color--primary;
            @include placeholder {
                opacity: .5;
            }
            & ~ .input--left-icon,
            & ~ .input--right-icon {
                border-color: $color--primary;
            }
        }

        &[disabled] {
            color: color('greys', 'light');
            background-color: lighten($fc,75%);
        }
    }

    @if $input == 'textarea' {
        #{$input} {
            height: $form-size * 5;
        }
    }
}

input[type="submit"],
button {
    cursor: pointer;
}


.input--left-icon-wrapper,
.input--right-icon-wrapper {
    position: relative;
}



// Radio + Checkbox
// --------------------------------------------------
// @example html - Usage
//  <div class="form-item--wide">
//     <div class="radio-container">
//         <div class="radio">
//             <input type="radio" name="" id="">
//             <label for="">Opt IN</label>
//         </div>
//         <div class="radio">
//             <input type="radio" name="" id="">
//             <label for="">Opt Out</label>
//         </div>
//     </div>
// </div>
// <div class="form-item--wide">
//     <div class="checkbox-container">
//         <div class="checkbox">
//             <input type="checkbox" name="" id="">
//             <label for="">Remember me</label>
//         </div>
//     </div>
// </div>

.radio-container,
.checkbox-container {

    @include clearfix;
    margin-top: 0.3125em;

    &--float {
        @extend .radio-container;
        .radio,
        .checkbox {
            float: left;
        }
    }

    input[type=radio],
    input[type=checkbox] {
        display: none;
    }

    label:before,
    input[type=radio] + label:after,
    input[type=checkbox] + label:after,
    input[type=radio]:checked + label:after {
        width: $radio-size;
        height: $radio-size;
    }

    label:before,
    input[type=radio] + label:after,
    input[type=checkbox] + label:after {
        position: absolute;
        left:0;
        top:0;
    }

    label {
        position: relative;
        display: inline-block;
        padding-left: $radio-size * 1.25;
        line-height: $radio-size;
        min-height: $radio-size;
        cursor: pointer;

        &:before {
            display: inline-block;
            content: "";
            background-color: $white;
            border: $form-border;
            transition: border-color $transition--fast ease-in-out;
        }
        &:hover:before {
            border-color: color('brand', 'primary');
        }
    }

    .radio, .checkbox {
        display: inline-block;
        margin-right: 1.25em;
        label {
            margin-bottom: .625em;
        }
    }

    .checkbox label:before {
        border-radius: 0;
    }
    .radio label:before {
        border-radius: 50%;
    }

    input[type=radio] + label:after {
        font-size: $radio-size / 1.8;
        content: $fa-var-circle;
    }
    input[type=checkbox] + label:after {
        font-size: $radio-size / 1.25;
        content: $fa-var-check;
    }

    input[type=radio] + label:after,
    input[type=checkbox] + label:after {
        @extend .fa;
        display: block;
        text-align: center;
        opacity: 0;
        line-height: $radio-size;
        color: color('brand', 'primary');
        transition: all $transition--fast ease-in-out;
        transform: scale(0);
    }
    input[type=radio]:checked + label:after,
    input[type=checkbox]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    .radio .parsley-error + label:before,
    .checkbox .parsley-error + label:before {
        border-color: color('brand', 'error');
    }
}


// Select
// --------------------------------------------------
// @example html - Usage
//   <select class="js-custom-select">
//     <option value="hide">All platforms</option>
//     <option value="mobile-game">Mobile game</option>
//      <option value="playstation">Playstation</option>
//     <option value="xbox">Xbox</option>
//   </select>

.select {
    position: relative;
    display: inline-block;
    //width: 100%;
    height: $form-size;
    cursor: pointer;


    select:focus ~ .select-styled,
    select:focus ~ .select-options {
        border-color: $color--primary;
    }

    .select-hidden {
        opacity:0;
        height: $form-size;
    }

    .select-styled {
        position: absolute;
        background-color: $white;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: $form-size;
        text-align: left;
        padding: 0 $form-padding;
        overflow: hidden;
        border: $form-border;
        border-radius: $form-radius;
        transition: border-radius $transition--fast ease-in-out;

        &:after {
            @extend .fa;
            font-size: 1.5em;
            content: $fa-var-angle-down;
            position: absolute;
            top: 0;
            right: 18px;
            line-height: $form-size;
            transition: transform $transition--fast ease-in-out;
        }

        &:focus,
        &:active,
        &.is-active {
            border-radius: $form-radius $form-radius  0 0;
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .select-options {
        height: 0;
        overflow: hidden;
        position: absolute;
        top:  100%;
        right: 0;
        left: 0;
        z-index: 50;
        text-align: left;
        background: $snow-white;
        border-radius: 0 0 $form-radius $form-radius;
        transition: height $transition--fast ease-in-out;
        max-height: 450%;
        overflow-y: auto;

        li {
            padding: $form-padding;
            list-style-type: none;
            &:before {
                display: none;
            }
            &:hover {
                background-color: color(brand,primary);
            }

            &:last-child {
                border-radius: 0 0 $form-radius $form-radius;
            }
            &[rel="hide"] {
                display: none;
            }
        }

        &.is-active {
            border: $form-border;
            margin-top: -1px;
            height: auto;
        }
    }

    &.is-inverted {
        .select-styled {
            &:focus,
            &:active,
            &.is-active {
                border-radius: 0 0 $form-radius $form-radius;
            }
        }

        .select-options {
            top: auto;
            bottom: 100%;
            margin-bottom: 0;
            border-radius: 27px 27px 0 0;

            &.is-active {
                margin-top: 0;
                margin-bottom: -1px;
            }

            li {
                &:first-child {
                    border-radius: $form-radius $form-radius 0 0;
                }

                &:last-child {
                    border-radius: 0;
                }
            }
        }
    }
}

// Input [type=file]
// --------------------------------------------------

input[type=file] {
    display: none;
}
.file-upload {
    cursor: pointer;
    display: block;
    margin-right: 10px;
    margin-bottom: $gutter-width / 2;
    @include mq(sm){
        display: inline-block;
    }
    label {
        @extend .btn--secondary;
        @extend .fa;
        @extend .fa-inbox;
        text-transform: none!important;
        line-height: 1;
        display: block!important;
        @include mq(sm){
            display: inline-block;
        }
       // white-space: nowrap;

        &:before {
            margin-right: .5em;
        }
        &.is-ready {
            @extend .fa-check-square-o;
            &:before {
                font-size: .95rem;
            }

        }
    }
}

.form__confirm {
    padding-bottom: 2.5em;
    color: $color--primary;
    text-align: center;
}

.form__error {
    padding-bottom: 2.5em;
    color: $color--error;
    text-align: center;
}

.form__message {
    padding-bottom: 2.5em;
}

// Loading
// --------------------------------------------------

.btn--loader {
    .fa-spinner {
        display: none;
        color: $snow-white;
        transform: animation $transition--fast;
    }

    &.is-loading {
        box-shadow: 0 3px color(greys,xdark);
        background-color: color(greys,dark);
        pointer-events: none;

        &:hover {
            background-color: color(greys,xdark);
        }

        span:not(.fa) {
            display: none;
        }

        .fa-spinner {
            display: inline-block;
            animation: spin 1s infinite;
        }
    }
}

@keyframes spin {
    from { transform: rotate(0); }
    to { transform: rotate(359deg); }
}

// textarea.parsley-error,
// textarea.parsley-error:focus,
// input.parsley-error,
// input.parsley-error:focus,
// .input--left-icon-wrapper input.parsley-error ~ .input--left-icon,
// .input--left-icon-wrapper input.parsley-error:focus ~ .input--left-icon,
// .input--right-icon-wrapper input.parsley-error ~ .input--right-icon,
// .input--right-icon-wrapper input.parsley-error:focus ~ .input--right-icon,
// label.parsley-error input[type="checkbox"]:after,
// label.parsley-error input[type="radio"]:after {
//     border-color: $color--error;
// }

// .parsley-errors-list {
//     li:before {
//         display: none;
//     }
//     list-style: none;
//     color: $color--error;
//     padding: 5px 0 0 $form-padding;
// }

.form-item.parsley-error,
.form-item--wide.parsley-error {
    input,
    select,
    textarea,
    input.input--left-icon ~ .fa,
    input.input--left-icon:focus ~ .fa,
    input.input--right-icon ~ .fa,
    input.input--right-icon:focus ~ .fa,
    .select-styled,
    .checkbox label:before,
    .radio label:before,
    .file-upload--btn label,
    .file-upload--input label {
        border-color: $color--error;
        color: $color--error;
    }
}

.parsley-errors-list {
    list-style: none;
    color: $color--error;
    .typography & li:before {
         display: none;
     }
    .form-item-wide & {
        float: left;
    }
    .form-item & {
        display: inline-block;
    }
    margin-bottom: $form-margin;
}
