// ==========================================================================
// Breadcrumb
// ==========================================================================

@include breadcrumb();

.breadcrumb {
    font: .9375em $ff--secondary;
    &+article {
        margin-top: 2.3125em;
    }
    a:hover,
    span {
        color: $color--primary;
    }
}
