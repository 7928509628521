// ========================================================
// Header
// ========================================================

@include banner('.header', 30em, sm, 4em);

.header {
    overflow: hidden;
    padding: 0;
    &:before {
        content:"";
        background: url(/dist/images/bg_dot_10x10.png) repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        z-index: 9;
        top: 0;
        left: 0;
    }
    .cover {
        z-index: 8;
    }

    .header__inner {
        @extend .typography;
        text-shadow: 0px 1px 1px $darth-vader;
        color: $snow-white;
        z-index: 10;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .preview {
            border-radius: 50%;
            display: inline-block;
            margin-bottom: 2em;
        }
        h1 {
            font-size: 2em;
            text-transform: uppercase;
            margin-top: 0;
            line-height: 1.1;
        }
        p {
            font: 300 1.25em $ff--primary;
        }
        @include mq(sm){
            min-height: auto;
        
            h1 {
                font-size: 3.75em;
            }
            p {
                font-size: 1.5em;
            }
        }

    }

    picture {
        img {
            height: 100%;
            object-fit: cover;
        }
    }
}

.header--bigger {
    height: 50vh;
    display: flex;
    align-items: center;

    @include mq(sm) {
        display: block;
        height: calc(100vh - 4rem); // 4rem is nav height
    }
}
