@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url(#{$ff__path}Roboto-normal-300.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url(#{$ff__path}Roboto-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url(#{$ff__path}Roboto-normal-700.woff) format('woff');
}

@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 300;
	src: url(#{$ff__path}Roboto_Condensed-normal-300.woff) format('woff');
}

@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: url(#{$ff__path}Roboto_Condensed-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	src: url(#{$ff__path}Roboto_Condensed-normal-700.woff) format('woff');
}
