// ==========================================================================
// Navigation
// ==========================================================================



@mixin nav-main($class: '.nav-main', $nav-height: 5rem, $responsive: true, $sticky: false) {

	@if $responsive {
		@include nav-icon( #{$class}__icon, $nav-height);
	}


	#{$class} {

		font-size: 1em;
		position: relative;
		z-index: map-get($zindexes, 'nav-main');
		width: 100%;
		text-align: center;

		@if $responsive {
			height: $nav-height;
			@include mq(xl) {
				height: auto;
			}
		}

		&__brand {
			img {
				height: calc( #{$nav-height} - #{$gutter-width};
				width: auto;
			}
			@if $responsive {
				position: relative;
				top: $gutter-width / 2;
				@include mq(xl) {
					position: absolute;
					left: $gutter-width / 2;
				}
			} @else {
				position: absolute;
				top: $gutter-width / 2;
				left: $gutter-width / 2;
			}
		}

		&__panel {

			@if $responsive {
				display: block;
				position: absolute;
				top: 0;


			    list-style: none;
			    overflow: hidden;
			    overflow-y: auto;

				width: $nav-mobile-width;
				height: calc(100vh - #{$nav-height});

				margin-top: $nav-height;

				text-align: left;

				@include mq(xl) {
					display: inline-block;
					position: static;
					text-align: center;
					width: 100%;

                    overflow: initial;

					height: auto;

					margin-top: 0;

					#{$class}__primary > li {
						display: inline-block;
						& > a {
							line-height: $nav-height;
						}
					}
				}
			} @else {

				display: inline-block;
				position: static;
				text-align: center;
				width: 100%;
				height: $nav-height;

				#{$class}__primary > li {
					display: inline-block;
					& > a {
						line-height: $nav-height;
					}
				}
			}
		}
	}

	.nav-main-handler {
        overflow-x: hidden;

		.overlay {
		    position: fixed;
		    top: 0;
		    left: 0;
		    width: 100vw;
		    height: 100vh;
		    background: rgba(0,0,0,0.25);
		    content: '';
		    opacity: 0;
		    transition: opacity 0.5s;
		    z-index:map-get($zindexes,overlay);
		    -webkit-backdrop-filter: blur(5px);
            will-change: opacity;
		}
		&.has-nav-main-open {
			overflow: hidden;
		    .overlay {
		        opacity: 1;
		        z-index: map-get($zindexes,overlay-visible);
		    }
		}
		&.will-push-left {
            #{$class}__panel {
                left: #{-$nav-mobile-width};
                transition: left .5s ease-out;
                will-change: left;
            }
            &.has-nav-main-open #{$class}__panel {
                left: 0;
            }
            &>*:not(#{$class}):not(.overlay):not(.mfp-wrap) {
                position: relative;
                transition: left .5s ease-out;
                left: 0;
                will-change: left;
            }
            &.has-nav-main-open>*:not(#{$class}):not(.overlay):not(.mfp-wrap) {
                left: $nav-mobile-width;
            }
        }
        &.will-push-right {
            #{$class}__panel {
                right: #{-$nav-mobile-width};
                transition: right .5s ease-out;
                will-change: right;
            }
            &.has-nav-main-open #{$class}__panel {
                right: 0;
            }
            &>*:not(#{$class}):not(.overlay):not(.mfp-wrap) {
                position: relative;
                transition: right .5s ease-out;
                right: 0;
                will-change: right;
            }
            &.has-nav-main-open>*:not(#{$class}):not(.overlay):not(.mfp-wrap) {
                right: $nav-mobile-width;
            }

        }
        &.will-zoom-out {
			perspective: 2000px;

            #{$class}__panel {
                left: #{-$nav-mobile-width};
                will-change: left;
            }

			&>*:not(#{$class}):not(.overlay):not(.mfp-wrap) {
				position: relative;
				transition: transform .5s;
				left: 0;
                will-change: left;
			}
			&.has-nav-main-open>*:not(#{$class}):not(.overlay):not(.mfp-wrap) {
				transform: translateZ(-100px);
		        transform-origin: center center;
		        transform-style: preserve-3d;
			}
		}
	}
}







