// ==========================================================================
// Variables
// ==========================================================================

// Global
$fs       : 16px;       // Font-size global (apply on htm, body)
$lh       : 1.4;        // Line-height global
$fc       : #222222;    // Font color

$globalia : #1982c4;

// $border__width  : 1px;
// $border__color  : lighten($fc,75%);
// $border__radius : 1em;

$margin   : 1em;
$padding  : 1em;

$border   : 1px solid lighten($fc,75%);
$radius   : 27px;

// Font families
$ff                    : "Roboto", Helvetica, sans-serif;
$ff__path              : '../fonts/';
$ff__primary           : $ff;
$ff__secondary         : "Roboto Condensed", Helvetica, sans-serif;
$ff__icon              : "";
$ff__fa                : "fontAwesome";

// Headings
$h1 : $fs * 2;          // 32px;
$h2 : $fs * 1.3125;     // 21px;
$h3 : $fs * 1.125;      // 18px;
$h4 : $fs;              // 16px;
$h5 : $fs * .875;       // 14px;
$h6 : $fs;              // 16px;

// Colors
$color--primary     : #97C945;
$color--secondary   : #2397CD;
$color--error       : #ff0000;
$snow-white         : #FFFFFF;
$darth-vader        : #000000;
$white              : $snow-white;
$black              : $darth-vader;


// Color mapping
// -------------
// @example scss - Usage
//   color: color('greys', 'xlight');
//   color: color('primary', 'dark');
//   color: color('secondary');
//   color: color('brands', 'facebook');
//
// return base color as default

$colors-project : (
    greys : (
        xdark       : #333333,
        dark        : #575757,
        base        : #d6d6d6,
        light       : #bebebe,
        xlight      : #F6F6F6
    )
);


// Transitions
$transition--fast   : 240ms;
$transition--normal : 480ms;
$transition--slow   : 720ms;


// Breakpoints
// -------------
// @example scss - Usage
//   @include mq(md) {...}
//
// create mobile-first media-query ( min-width >= breakpoint )
//
$breakpoints : (
    r:    0, // 528px
    xs:   40em, // 640px
    sm:   48em, // 768px
    mmd:  60em, // 960px
    md:   64em, // 1024px
    lg:   80em, // 1280px
    xl:   87.5em  // 1400px
);

// Grid settings
// -------------
// @example scss - Usage
//     @include grid(wrapper-class, $grid-columns, $gutter-width, $container-width, flexbox-enabled?);
//     additionnal params:
//          wrapper-class (ex:'.grid')
//          flexbox-enabled (ex:false)
//
// create responsive grid based on $breakpoints
//
$grid-columns       : 12;
$gutter-width       : 30px;
$container-width    : 1280px;

// Components

$zindexes: (
    overlay             : -1,
    overlay-visible     : 9,
    nav-main            : 1100,
    nav-icon            : 1101
);

// Navigation

$nav-icon-bar-width         : 3px;
$nav-icon-bar-color         : $darth-vader;
$nav-mobile-width           : 370px !default;
$nav-mobile-animation    : ".will-push-right"; //Values: [".will-push-left", ".will-zoom-out"]

// Buttons





$picture__ratio         : '4/3';
$picture__ratio--apply  : true;


