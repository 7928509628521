.sticky-subscription {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    background: color(greys, xlight);
    border-top: 3px solid darken($snow-white,15%);
    z-index: 100;
    text-align: center;
    transition: bottom $transition--normal;

    &.is-hidden {
        bottom: - 100%;
    }
}

.typography .container#sticky-stop {
    margin-top: 0;
    margin-bottom: 1rem;
}

.typography .subscription-examen {
    margin-top: 0;
}
