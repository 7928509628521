// ==========================================================================
// Mixins
// ==========================================================================


/// Change placeholder color
/// --------------------------------------------------
///
/// @example scss - Usage
///   input {
///     @include placeholder { color: red; }
///   }

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder { @content; }
  }
}


/// Rem with pixel fallback
/// --------------------------------------------------
///
/// @example scss - Usage
///   @include rem('padding',10px 15px)
///   @include rem('font-size',10px)

@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: strip-unit($value);
      $base: strip-unit($fs);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / $base + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * $base + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}


/// Provides an easy way to include a clearfix for containing floats.
/// --------------------------------------------------
///
/// @link http://cssmojo.com/latest_new_clearfix_so_far/
///
/// @example scss - Usage
///   .element {
///     @include clearfix;
///   }
///
/// @example css - CSS Output
///   .element::after {
///     clear: both;
///     content: "";
///     display: table;
///   }

@mixin clearfix {
  &::after,
  &::before {
    clear: both;
    content: "";
    display: table;
  }
}


/// Generate font-faces
/// --------------------------------------------------
///
/// @example scss - Usage
///   @include font-face(fontName, #{$ff__path}, bold);
///   @include font-face(fontName, #{$ff__path}, normal, italic);

@mixin font-face ( $font-family, $filename, $weight : normal, $style : normal ) {
  @font-face {
    font-family: $font-family;
    src: url($filename + '.eot');
    src: url($filename + '.eot?#iefix') format('embedded-opentype'),
         url($filename + '.woff') format('woff'),
         url($filename + '.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}


/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
/// --------------------------------------------------
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
/// @todo Remove height argument in v5.0.0

@mixin hide-text($height: null) {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;

  @if $height {
    @warn "The `hide-text` mixin has changed and no longer requires a height. The height argument will no longer be accepted in v5.0.0";
  }
}


/// Media-query generator
/// --------------------------------------------------
///
/// Requires map-variables $breakpoints
///
/// @example scss - Usage
///   @include mq(sm) {
///     ...
///   }
///
/// @example css - CSS Output
///   @media only screen and (min-width:48em) {
///     ...
///   }

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}


/// Grid Custom System
/// --------------------------------------------------
///
/// Requires map-variables $breakpoints
///
/// @example scss - Usage
///     @include grid(wrapper-class, columns, gutter-width, max-width, flexbox-enabled?);
///     params:  wrapper-class (ex:'.grid')
///              columns (ex:12)
///              gutter-width (ex:10)
///              max-width (ex:100%)
///              flexbox-enabled (ex:false)
///
/// @example css - CSS Output (flexbox-enabled=false)
///    .grid {
///       max-width : 100%;
///       (...)
///    }
///    .row  {
///       margin-left : -5px;
///       margin-right : -5px;
///       (...)
///    }
///     .col-(breakpoint)-(1-12) {
///       float: left;
///       width : 100%/(1-12);
///       padding: 0 5px 10px;
///       (...)
///    }

@mixin grid($class: '.grid', $grid-columns : 12, $gutter : 0, $max-width : false, $flexbox-enabled : false){

    #{$class} {
        width: 100%;
        @if($max-width == false){
            $max-width : calc(100% - #{$gutter});
        }
        max-width: $max-width;
        margin: 0 auto;
        overflow: hidden;
        @if($gutter != 0){
            padding: 0 $gutter/2;
        }
        // Rows
        .row {
            display: block;
            margin-left:-$gutter/2;
            margin-right:-$gutter/2;
            position: relative;

            @if $flexbox-enabled {
                :root.webkitbox &,
                :root.flexbox & {
                    display: flex;
                    flex: 0 1 auto;
                    flex-direction: row;
                    flex-wrap: wrap;

                    &.reverse {
                        flex-direction: row-reverse;
                    }
                }
            }
            // Columns
            & > * {
                display: block;
                width: 100%;
                @if($gutter != 0){
                    padding: 0 $gutter/2 $gutter;
                }
                @if $flexbox-enabled {
                    :root.webkitbox &,
                    :root.flexbox & {
                        &.reverse {
                            flex-direction: column-reverse;
                        }
                    }
                }
            }
        }
        @each $bp, $w in $breakpoints {
            @media only screen and (min-width: #{$w}) {
                @for $i from 1 through $grid-columns {

                    @if $flexbox-enabled {
                        :root.webkitbox &,
                        :root.flexbox &{
                            .col-#{$bp}-#{$i} {
                                flex: 0 0 auto;
                                flex-basis: 100% / $grid-columns * $i;
                                max-width: 100% / $grid-columns * $i;
                            }
                            .col-#{$bp}-auto {
                                flex-grow: 1;
                                flex-basis: 0;
                                max-width: 100%;
                            }
                            .start-#{$bp} {
                                justify-content: flex-start;
                                text-align: start;
                            }
                            .center-#{$bp} {
                                justify-content: center;
                                text-align: center;
                            }
                            .end-#{$bp} {
                                justify-content: flex-end;
                                text-align: end;
                            }
                            .top-#{$bp} {
                                align-items: flex-start;
                            }
                            .middle-#{$bp} {
                                align-items: center;
                            }
                            .bottom-#{$bp} {
                                align-items: flex-end;
                            }
                            .around-#{$bp} {
                                justify-content: space-around;
                            }
                            .between-#{$bp} {
                                justify-content: space-between;
                            }
                            .first-#{$bp} {
                                order: -1;
                            }
                            .last-#{$bp} {
                                order: 1;
                            }
                        }
                        :root.no-webkitbox &,
                        :root.no-flexbox & {
                            .col-#{$bp}-#{$i} {
                                float: left;
                                width: 100% / $grid-columns * $i;
                            }
                        }
                     } @else {
                        .col-#{$bp}-#{$i} {
                            float: left;
                            width: 100% / $grid-columns * $i;
                        }
                    }
                    .col-#{$bp}-offset-#{$i} {
                        margin-left: 100% / $grid-columns * $i;
                    }
                    .col-#{$bp}-pull-#{$i} {
                        position: relative;
                        right: percentage(($i / $grid-columns));
                    }
                    .col-#{$bp}-push-#{$i} {
                        position: relative;
                        left: percentage(($i / $grid-columns));
                    }
                }
            }
        }
    }
}


/// Ratio Maintain System
/// --------------------------------------------------
///
/// Requires map-variables $breakpoints
///
/// @example scss - Usage
///   figure {
///     @include keepRatio('16/9');
///     img,
///     iframe {
///       ...
///     }
///   }

@mixin keepRatio($ratio:'1/1'){

    $ratio1:str-slice($ratio,1,(str-index($ratio,'/') - 1));
    $ratio2:str-slice($ratio,(str-index($ratio,'/') + 1));
    position: relative;
    overflow: hidden;
    &:before{
        content:'';
        display: block;
        width: 100%;
        padding-top: to-number($ratio2) * 100 / to-number($ratio1) * 1%;
    }

    >img {
      display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        min-height: 100%;
    }

    >iframe,
    >video{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
        transform: translate3d(-50%,-50%,0);
        height: 100%;
        width: 100%;
    }
}

/// Ratio Maintain Helper Functions

@function gcd($a,$b){
  @if $b == 0{
    @return $a;
  }@else{
    @return gcd($b,$a % $b);
  }
}
@function getRatio($w,$h){
  $r:gcd($w,$h);
  @return "#{$w / $r}/#{$h/$r}";
}
@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: log('Value for `to-number` should be a number or a string.');
  }
  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);
    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
    }
    @if $character == '.' {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }
  @return if($minus, -$result, $result);;
}
