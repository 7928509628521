.emphasis {
    margin: 1em 0;
    figure {
        overflow: hidden;
        display: table;
        width: 100%;
        >img {
            display: table-cell;
            border-radius: 50%;
            float: left;
            margin-right: $gutter-width;
            max-width: 140px;
            &+figcaption {
                min-height: 140px;
            }
        }
        picture {
            display: block;
            border-radius: 50%;
            float: left;
            margin-right: $gutter-width;
            width: 140px;
            @include keepRatio();
            -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
            &+figcaption {
                min-height: 140px;
            }
        }
        figcaption {
            display: table-cell;
            position: relative;
            vertical-align: middle;
            text-align: left;
            width: 100%;


            p {
                margin: 0;
            }

            &.emp--primary,
            &.emp--secondary {
                padding: 1em 0 1em calc( 6px + #{$gutter-width});
            }
            &.emp--primary:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                border-radius: 6px;
                width: 12px;
                height: 100%;
                background: color(brand,primary);
            }
            &.emp--secondary:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                border-radius: 6px;
                width: 12px;
                height: 100%;
                background: color(brand,secondary);
            }
            &.emp--quote {
                font-size: 1.5em;
                font-weight: 300;
            }
        }
    }
}
