// ==========================================================================
// Cards
// ==========================================================================

@include card('.entry-box', false, 10px, 2.3125em);

.entry-box {
    background: $snow-white;
    border-bottom: 3px solid color(greys, base);
    text-align: center;
    margin: 1em 0 1.125em;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
    padding: 2.3125em 1em;

    figure img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
        filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");

    }

    &:hover figure img {
        -webkit-filter: grayscale(0);
        filter: none;
    }

    &__title {
        display: table;
        margin-bottom: 2.3125em;
        width: 100%;
        &__inner {
            font: 400 1.125em $ff--secondary;;
            text-transform: uppercase;
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__wrapper {
        max-width: 850px;
        margin: 0 auto;
    }

    &__document {
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 100%;
        }

        &--fallback {
            img {
                max-width: 100px;
            }
        }
    }
}

@include card('.testimonial', false, false, 0);

.testimonial {
    color: $snow-white;
    text-align: center;
    quote {
        display: block;
        position: relative;
        background: rgba(0,0,0,0.3);
        padding: 1.15625rem;
        border-radius: 10px;
        min-height: 180px;
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid rgba(0,0,0,0.3);
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -10px;
        }
    }

    &__footer {
        display: inline-block;
        img {
            width: 90px;
            height: 90px;
            border-radius: 100%;
            margin: 1em auto;
        }
    }

    .slickable & {
        margin: 2em;
    }

    &__content {
        display: none;
        &:nth-child(1) {
            display: block;
        }
    }
}


@include card('.trainer', false, 10px, 2em);

.trainer {

    // Comment ou the borders and background colors from the card to maybe use them later as it is a component ?

    //background: $snow-white;
    text-align: left;

    margin: 0;
    // -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
    // border-width: 0 0 3px 0;
    // border-color: color(greys,base);

    &__header {
        margin: -2em -2em 0;
    }
    &__title,
    &__name {
        font-size: $h2;
        text-transform: uppercase;
        font-family: $ff--secondary;
        line-height: $lh;
        font-weight: 700;
    }
    &__name--alt {
        color: color(brand, secondary);
        font-weight: 500;
        font-size: 1.5em;
    }
    .team-member &__name {
        color: color(brand, secondary);
    }
    &__titles {
        list-style: none;
        margin: .75em 0;
        li {
            display: inline-block;
            padding: .5em;
            background: color(brand, secondary);
            color: $snow-white;
            &:before {
                display: none!important;
            }
            &+li {
                margin-top: 0!important;
            }
        }
    }
    &__titles--alt {
        margin-top:0;
        li {
            background: none;
            color: #000;
            font-size: 1em;
            padding: 0;
        }
    }
    &__position {
        color: color(greys, dark);
    }
    &__position--alt {
        margin-bottom: 10px;
    }
    &__link {
        color: color(brand, primary);
        font-weight: 600;
    }
}
