// ========================================================
// Formations continues
// ========================================================

%formations--center {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.formations {

    h2 {
        margin-bottom: 0.5em;
    }

    &__ctas {
        @extend %formations--center;
    }

    &-open {
        .icon {
           background: url(../../dist/images/ic-open-formations.png) no-repeat;
           background-position: center;
           color: $white;
           border: 2px solid $white;
        }
        h2 {
            color: $white;
        }
        .formations__ctas {
            .formation__cta {
                @include mq(md) {
                    width: 33.33333333333%;
                };
            }
        }
    }

    &-next {
         .icon {
            background: url(../../dist/images/ic-next-formations.png) no-repeat;
            background-position: center;
        }
    }

    &-interest {
        .icon {
           background: url(../../dist/images/ic-interest-formations.png) no-repeat;
           background-position: center;
        }
    }

}

.formation--page {
    position: relative;

    .entry-box__document {
        @include mq(sm) {
            padding-bottom: 3em;
        }
    }

    .btn--primary {
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        @include mq(sm) {
            position: absolute;
            bottom: 2em;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 2em);
        }
    }
}
