// ==========================================================================
// Buttons
// ==========================================================================

@include btn('.btn', 0, 50px, 0, 0);

.btn {
    padding: 0.925rem 1.75rem;
    color: $snow-white;
    text-transform: uppercase;
    //white-space: nowrap;
    text-align: center;
    box-shadow: 0 3px color(greys,xdark);
    background-color: color(greys,dark);
    transition: background-color 300ms ease-out;
    cursor: pointer;
    &:hover {
        background-color: color(greys,xdark);
    }
}

.btn--primary {
    @extend .btn;
    box-shadow: 0 3px darken(color(brand,primary),15%);
    background-color: color(brand,primary);
    &:hover {
        background-color: darken(color(brand,primary),15%);
    }
}

.btn--secondary {
    @extend .btn;
    box-shadow: 0 3px darken(color(brand,secondary),15%);
    background-color: color(brand,secondary);
    &:hover {
        background-color: darken(color(brand,secondary),15%);
    }

    form & {
        padding: 0.925rem 1rem;
    }
}

.btn--outline {
    @extend .btn;
    border: 2px solid $snow-white;
    box-shadow: none;
    background: none;
}

.btn--large {
    margin: 2 * $margin auto;
}
