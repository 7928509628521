// ========================================================
// Footer
// ========================================================

footer {
    padding-top: 2.3125em;
    padding-bottom: 2.3125em;
    border-top: #{$border};
    &__title {
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: .5em;
    }

    ul {
        list-style: none;
        margin-bottom: 1em;
        li {
            line-height: 1.6;
            font-size: .8em;
            a {
                @extend .alt-link;
                font-size: inherit;
            }
        }
        &.accreditation {
            li {
               line-height: 1.4;
               margin-bottom: 1em;
               font-size: .75em;
            }
        }
    }

    .footer__legal {
        color: color(greys,dark);
        font-size: .75em;
        text-align: center;
        margin-top: $margin;
        @include mq(sm) {
            &>div:nth-child(1){ text-align: left; }
            &>div:nth-child(2){ text-align: right; }
        }
    }
}
