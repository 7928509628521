.social-icons {
    margin-top: 1em;
    float: right;
    span {
        float: left;
        margin: 0 5px;
        color: color(greys, dark);
        line-height: calc( 2em + 3px);
    }
	a {
		&:before {
			@extend .fa;
			line-height: inherit;
			font-size: 1em;
	    }
        float: left;
        margin: 0 5px;
        border-radius: 50%;
        text-align: center;
        color: #FFFFFF;
        width: 2em;
        height: 2em;
        line-height: 2em;
		@each $social, $icon in $socials {
			&[href*="#{$social}"]:not(footer a) {
				background-color: map-get($icon, color);
                box-shadow: 0 3px darken(map-get($icon, color),15%);
			}

            &[href*="#{$social}"]:before {
                content: map-get($icon, char);
            }
		}
        span {
            display: none;
        }
	}
}

.footer__social .social-icons--footer {
    float: left;
    overflow: visible;
    margin-bottom: 1rem;
    width: 100%;

    @include mq(sm) {
        margin-top: 0;
    }
}

.footer__social .social-icons__intro {
    margin-right: .5rem;
    margin-left: 0;
    font-size: 1.2em;
    line-height: calc(1.5em + 5px);
}

.footer__social {
    margin-top: 1rem;
    margin-bottom: 2rem;
}
