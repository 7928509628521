// ==========================================================================
// Expander
// ==========================================================================

.expander {
    @extend .typography;
    &>label {
        display: block;
    }
    .a {
        display: none;
    }
    .q {
        cursor: pointer;
    }
    &>input {
        display: none;
        &:checked ~ label {
            padding-bottom: 1em;
        }
        &:checked ~ .a {
            display: block;
        }
    }
}


.faq-expander {
    @extend .expander;
    .q {
        font: 400 1.625em / 1 $ff--secondary;
        padding: 1.8em 0;
        &:before {
            @extend .fa;
            @extend .fa-plus-square-o;
            color: color(brand, primary);
            margin-right: $gutter-width / 2;
        }
    }
    &+.expander {
        border-top: $border;
    }
    input {
        &:checked ~ .a {
            padding: 0 0 1em 0;
        }
        &:checked ~ label {
            padding-bottom: 0;
        }
        &:checked ~ label .q:before {
            @extend .fa-minus-square-o;
        }
    }
}
