[gr-grid~=row--block] {
    position: relative;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-left: -15px;
    margin-right: -15px;
}

[gr-grid=block] {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
}

@media only screen and (min-width: 375px) {
    [gr-grid~=row] [gr-grid~='xs-1'] {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-1'] [gr-grid=block] {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    [gr-grid~=row--block][gr-grid~='xs-1'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-1'] [gr-grid=block]:nth-of-type(n + 2) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-1'] {
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-1'] {
        margin-left: 8.33333%;
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-1'] {
        margin-left: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-1'] {
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-2'] {
        -ms-flex-positive: 2;
        flex-grow: 2;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-2'] [gr-grid=block] {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    [gr-grid~=row--block][gr-grid~='xs-2'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-2'] [gr-grid=block]:nth-of-type(n + 3) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-2'] {
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-2'] {
        margin-left: 16.66667%;
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-2'] {
        margin-left: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-2'] {
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-3'] {
        -ms-flex-positive: 3;
        flex-grow: 3;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-3'] [gr-grid=block] {
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    [gr-grid~=row--block][gr-grid~='xs-3'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-3'] [gr-grid=block]:nth-of-type(n + 4) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-3'] {
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-3'] {
        margin-left: 25%;
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-3'] {
        margin-left: 25%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-3'] {
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='xs-4'] {
        -ms-flex-positive: 4;
        flex-grow: 4;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-4'] [gr-grid=block] {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    [gr-grid~=row--block][gr-grid~='xs-4'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-4'] [gr-grid=block]:nth-of-type(n + 5) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-4'] {
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-4'] {
        margin-left: 33.33333%;
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-4'] {
        margin-left: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-4'] {
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-5'] {
        -ms-flex-positive: 5;
        flex-grow: 5;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-5'] [gr-grid=block] {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    [gr-grid~=row--block][gr-grid~='xs-5'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-5'] [gr-grid=block]:nth-of-type(n + 6) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-5'] {
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-5'] {
        margin-left: 41.66667%;
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-5'] {
        margin-left: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-5'] {
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-6'] {
        -ms-flex-positive: 6;
        flex-grow: 6;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-6'] [gr-grid=block] {
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }

    [gr-grid~=row--block][gr-grid~='xs-6'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-6'] [gr-grid=block]:nth-of-type(n + 7) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-6'] {
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-6'] {
        margin-left: 50%;
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-6'] {
        margin-left: 50%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-6'] {
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='xs-7'] {
        -ms-flex-positive: 7;
        flex-grow: 7;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-7'] [gr-grid=block] {
        -ms-flex-preferred-size: 14.28571%;
        flex-basis: 14.28571%;
        max-width: 14.28571%;
    }

    [gr-grid~=row--block][gr-grid~='xs-7'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-7'] [gr-grid=block]:nth-of-type(n + 8) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-7'] {
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-7'] {
        margin-left: 58.33333%;
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-7'] {
        margin-left: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-7'] {
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-8'] {
        -ms-flex-positive: 8;
        flex-grow: 8;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-8'] [gr-grid=block] {
        -ms-flex-preferred-size: 12.5%;
        flex-basis: 12.5%;
        max-width: 12.5%;
    }

    [gr-grid~=row--block][gr-grid~='xs-8'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-8'] [gr-grid=block]:nth-of-type(n + 9) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-8'] {
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-8'] {
        margin-left: 66.66667%;
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-8'] {
        margin-left: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-8'] {
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-9'] {
        -ms-flex-positive: 9;
        flex-grow: 9;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-9'] [gr-grid=block] {
        -ms-flex-preferred-size: 11.11111%;
        flex-basis: 11.11111%;
        max-width: 11.11111%;
    }

    [gr-grid~=row--block][gr-grid~='xs-9'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-9'] [gr-grid=block]:nth-of-type(n + 10) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-9'] {
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-9'] {
        margin-left: 75%;
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-9'] {
        margin-left: 75%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-9'] {
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='xs-10'] {
        -ms-flex-positive: 10;
        flex-grow: 10;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-10'] [gr-grid=block] {
        -ms-flex-preferred-size: 10%;
        flex-basis: 10%;
        max-width: 10%;
    }

    [gr-grid~=row--block][gr-grid~='xs-10'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-10'] [gr-grid=block]:nth-of-type(n + 11) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-10'] {
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-10'] {
        margin-left: 83.33333%;
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-10'] {
        margin-left: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-10'] {
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='xs-11'] {
        -ms-flex-positive: 11;
        flex-grow: 11;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-11'] [gr-grid=block] {
        -ms-flex-preferred-size: 9.09091%;
        flex-basis: 9.09091%;
        max-width: 9.09091%;
    }

    [gr-grid~=row--block][gr-grid~='xs-11'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-11'] [gr-grid=block]:nth-of-type(n + 12) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-11'] {
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-11'] {
        margin-left: 91.66667%;
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-11'] {
        margin-left: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-11'] {
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='xs-12'] {
        -ms-flex-positive: 12;
        flex-grow: 12;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-12'] [gr-grid=block] {
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }

    [gr-grid~=row--block][gr-grid~='xs-12'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xs-12'] [gr-grid=block]:nth-of-type(n + 13) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-left-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xs-offset-right-12'] {
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xs-offset-12'] {
        margin-left: 100%;
        margin-right: 100%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-left-12'] {
        margin-left: 100%;
    }

    [gr-grid~=row] [gr-grid~='xs-offset-right-12'] {
        margin-right: 100%;
    }
}

@media only screen and (min-width: 768px) {
    [gr-grid~=row] [gr-grid~='sm-1'] {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-1'] [gr-grid=block] {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    [gr-grid~=row--block][gr-grid~='sm-1'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-1'] [gr-grid=block]:nth-of-type(n + 2) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-1'] {
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-1'] {
        margin-left: 8.33333%;
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-1'] {
        margin-left: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-1'] {
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-2'] {
        -ms-flex-positive: 2;
        flex-grow: 2;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-2'] [gr-grid=block] {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    [gr-grid~=row--block][gr-grid~='sm-2'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-2'] [gr-grid=block]:nth-of-type(n + 3) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-2'] {
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-2'] {
        margin-left: 16.66667%;
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-2'] {
        margin-left: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-2'] {
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-3'] {
        -ms-flex-positive: 3;
        flex-grow: 3;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-3'] [gr-grid=block] {
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    [gr-grid~=row--block][gr-grid~='sm-3'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-3'] [gr-grid=block]:nth-of-type(n + 4) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-3'] {
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-3'] {
        margin-left: 25%;
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-3'] {
        margin-left: 25%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-3'] {
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='sm-4'] {
        -ms-flex-positive: 4;
        flex-grow: 4;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-4'] [gr-grid=block] {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    [gr-grid~=row--block][gr-grid~='sm-4'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-4'] [gr-grid=block]:nth-of-type(n + 5) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-4'] {
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-4'] {
        margin-left: 33.33333%;
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-4'] {
        margin-left: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-4'] {
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-5'] {
        -ms-flex-positive: 5;
        flex-grow: 5;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-5'] [gr-grid=block] {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    [gr-grid~=row--block][gr-grid~='sm-5'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-5'] [gr-grid=block]:nth-of-type(n + 6) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-5'] {
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-5'] {
        margin-left: 41.66667%;
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-5'] {
        margin-left: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-5'] {
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-6'] {
        -ms-flex-positive: 6;
        flex-grow: 6;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-6'] [gr-grid=block] {
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }

    [gr-grid~=row--block][gr-grid~='sm-6'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-6'] [gr-grid=block]:nth-of-type(n + 7) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-6'] {
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-6'] {
        margin-left: 50%;
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-6'] {
        margin-left: 50%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-6'] {
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='sm-7'] {
        -ms-flex-positive: 7;
        flex-grow: 7;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-7'] [gr-grid=block] {
        -ms-flex-preferred-size: 14.28571%;
        flex-basis: 14.28571%;
        max-width: 14.28571%;
    }

    [gr-grid~=row--block][gr-grid~='sm-7'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-7'] [gr-grid=block]:nth-of-type(n + 8) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-7'] {
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-7'] {
        margin-left: 58.33333%;
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-7'] {
        margin-left: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-7'] {
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-8'] {
        -ms-flex-positive: 8;
        flex-grow: 8;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-8'] [gr-grid=block] {
        -ms-flex-preferred-size: 12.5%;
        flex-basis: 12.5%;
        max-width: 12.5%;
    }

    [gr-grid~=row--block][gr-grid~='sm-8'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-8'] [gr-grid=block]:nth-of-type(n + 9) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-8'] {
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-8'] {
        margin-left: 66.66667%;
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-8'] {
        margin-left: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-8'] {
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-9'] {
        -ms-flex-positive: 9;
        flex-grow: 9;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-9'] [gr-grid=block] {
        -ms-flex-preferred-size: 11.11111%;
        flex-basis: 11.11111%;
        max-width: 11.11111%;
    }

    [gr-grid~=row--block][gr-grid~='sm-9'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-9'] [gr-grid=block]:nth-of-type(n + 10) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-9'] {
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-9'] {
        margin-left: 75%;
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-9'] {
        margin-left: 75%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-9'] {
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='sm-10'] {
        -ms-flex-positive: 10;
        flex-grow: 10;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-10'] [gr-grid=block] {
        -ms-flex-preferred-size: 10%;
        flex-basis: 10%;
        max-width: 10%;
    }

    [gr-grid~=row--block][gr-grid~='sm-10'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-10'] [gr-grid=block]:nth-of-type(n + 11) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-10'] {
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-10'] {
        margin-left: 83.33333%;
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-10'] {
        margin-left: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-10'] {
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='sm-11'] {
        -ms-flex-positive: 11;
        flex-grow: 11;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-11'] [gr-grid=block] {
        -ms-flex-preferred-size: 9.09091%;
        flex-basis: 9.09091%;
        max-width: 9.09091%;
    }

    [gr-grid~=row--block][gr-grid~='sm-11'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-11'] [gr-grid=block]:nth-of-type(n + 12) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-11'] {
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-11'] {
        margin-left: 91.66667%;
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-11'] {
        margin-left: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-11'] {
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='sm-12'] {
        -ms-flex-positive: 12;
        flex-grow: 12;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-12'] [gr-grid=block] {
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }

    [gr-grid~=row--block][gr-grid~='sm-12'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='sm-12'] [gr-grid=block]:nth-of-type(n + 13) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-left-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='sm-offset-right-12'] {
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='sm-offset-12'] {
        margin-left: 100%;
        margin-right: 100%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-left-12'] {
        margin-left: 100%;
    }

    [gr-grid~=row] [gr-grid~='sm-offset-right-12'] {
        margin-right: 100%;
    }
}

@media only screen and (min-width: 1024px) {
    [gr-grid~=row] [gr-grid~='md-1'] {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-1'] [gr-grid=block] {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    [gr-grid~=row--block][gr-grid~='md-1'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-1'] [gr-grid=block]:nth-of-type(n + 2) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-1'] {
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-1'] {
        margin-left: 8.33333%;
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-1'] {
        margin-left: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-1'] {
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-2'] {
        -ms-flex-positive: 2;
        flex-grow: 2;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-2'] [gr-grid=block] {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    [gr-grid~=row--block][gr-grid~='md-2'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-2'] [gr-grid=block]:nth-of-type(n + 3) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-2'] {
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-2'] {
        margin-left: 16.66667%;
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-2'] {
        margin-left: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-2'] {
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-3'] {
        -ms-flex-positive: 3;
        flex-grow: 3;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-3'] [gr-grid=block] {
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    [gr-grid~=row--block][gr-grid~='md-3'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-3'] [gr-grid=block]:nth-of-type(n + 4) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-3'] {
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-3'] {
        margin-left: 25%;
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-3'] {
        margin-left: 25%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-3'] {
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='md-4'] {
        -ms-flex-positive: 4;
        flex-grow: 4;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-4'] [gr-grid=block] {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    [gr-grid~=row--block][gr-grid~='md-4'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-4'] [gr-grid=block]:nth-of-type(n + 5) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-4'] {
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-4'] {
        margin-left: 33.33333%;
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-4'] {
        margin-left: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-4'] {
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-5'] {
        -ms-flex-positive: 5;
        flex-grow: 5;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-5'] [gr-grid=block] {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    [gr-grid~=row--block][gr-grid~='md-5'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-5'] [gr-grid=block]:nth-of-type(n + 6) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-5'] {
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-5'] {
        margin-left: 41.66667%;
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-5'] {
        margin-left: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-5'] {
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-6'] {
        -ms-flex-positive: 6;
        flex-grow: 6;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-6'] [gr-grid=block] {
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }

    [gr-grid~=row--block][gr-grid~='md-6'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-6'] [gr-grid=block]:nth-of-type(n + 7) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-6'] {
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-6'] {
        margin-left: 50%;
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-6'] {
        margin-left: 50%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-6'] {
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='md-7'] {
        -ms-flex-positive: 7;
        flex-grow: 7;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-7'] [gr-grid=block] {
        -ms-flex-preferred-size: 14.28571%;
        flex-basis: 14.28571%;
        max-width: 14.28571%;
    }

    [gr-grid~=row--block][gr-grid~='md-7'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-7'] [gr-grid=block]:nth-of-type(n + 8) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-7'] {
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-7'] {
        margin-left: 58.33333%;
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-7'] {
        margin-left: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-7'] {
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-8'] {
        -ms-flex-positive: 8;
        flex-grow: 8;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-8'] [gr-grid=block] {
        -ms-flex-preferred-size: 12.5%;
        flex-basis: 12.5%;
        max-width: 12.5%;
    }

    [gr-grid~=row--block][gr-grid~='md-8'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-8'] [gr-grid=block]:nth-of-type(n + 9) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-8'] {
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-8'] {
        margin-left: 66.66667%;
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-8'] {
        margin-left: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-8'] {
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-9'] {
        -ms-flex-positive: 9;
        flex-grow: 9;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-9'] [gr-grid=block] {
        -ms-flex-preferred-size: 11.11111%;
        flex-basis: 11.11111%;
        max-width: 11.11111%;
    }

    [gr-grid~=row--block][gr-grid~='md-9'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-9'] [gr-grid=block]:nth-of-type(n + 10) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-9'] {
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-9'] {
        margin-left: 75%;
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-9'] {
        margin-left: 75%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-9'] {
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='md-10'] {
        -ms-flex-positive: 10;
        flex-grow: 10;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-10'] [gr-grid=block] {
        -ms-flex-preferred-size: 10%;
        flex-basis: 10%;
        max-width: 10%;
    }

    [gr-grid~=row--block][gr-grid~='md-10'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-10'] [gr-grid=block]:nth-of-type(n + 11) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-10'] {
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-10'] {
        margin-left: 83.33333%;
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-10'] {
        margin-left: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-10'] {
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='md-11'] {
        -ms-flex-positive: 11;
        flex-grow: 11;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-11'] [gr-grid=block] {
        -ms-flex-preferred-size: 9.09091%;
        flex-basis: 9.09091%;
        max-width: 9.09091%;
    }

    [gr-grid~=row--block][gr-grid~='md-11'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-11'] [gr-grid=block]:nth-of-type(n + 12) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-11'] {
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-11'] {
        margin-left: 91.66667%;
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-11'] {
        margin-left: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-11'] {
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='md-12'] {
        -ms-flex-positive: 12;
        flex-grow: 12;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-12'] [gr-grid=block] {
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }

    [gr-grid~=row--block][gr-grid~='md-12'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='md-12'] [gr-grid=block]:nth-of-type(n + 13) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='md-offset-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-left-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='md-offset-right-12'] {
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='md-offset-12'] {
        margin-left: 100%;
        margin-right: 100%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-left-12'] {
        margin-left: 100%;
    }

    [gr-grid~=row] [gr-grid~='md-offset-right-12'] {
        margin-right: 100%;
    }
}

@media only screen and (min-width: 1280px) {
    [gr-grid~=row] [gr-grid~='lg-1'] {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-1'] [gr-grid=block] {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    [gr-grid~=row--block][gr-grid~='lg-1'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-1'] [gr-grid=block]:nth-of-type(n + 2) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-1'] {
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-1'] {
        margin-left: 8.33333%;
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-1'] {
        margin-left: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-1'] {
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-2'] {
        -ms-flex-positive: 2;
        flex-grow: 2;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-2'] [gr-grid=block] {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    [gr-grid~=row--block][gr-grid~='lg-2'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-2'] [gr-grid=block]:nth-of-type(n + 3) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-2'] {
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-2'] {
        margin-left: 16.66667%;
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-2'] {
        margin-left: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-2'] {
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-3'] {
        -ms-flex-positive: 3;
        flex-grow: 3;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-3'] [gr-grid=block] {
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    [gr-grid~=row--block][gr-grid~='lg-3'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-3'] [gr-grid=block]:nth-of-type(n + 4) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-3'] {
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-3'] {
        margin-left: 25%;
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-3'] {
        margin-left: 25%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-3'] {
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='lg-4'] {
        -ms-flex-positive: 4;
        flex-grow: 4;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-4'] [gr-grid=block] {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    [gr-grid~=row--block][gr-grid~='lg-4'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-4'] [gr-grid=block]:nth-of-type(n + 5) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-4'] {
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-4'] {
        margin-left: 33.33333%;
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-4'] {
        margin-left: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-4'] {
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-5'] {
        -ms-flex-positive: 5;
        flex-grow: 5;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-5'] [gr-grid=block] {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    [gr-grid~=row--block][gr-grid~='lg-5'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-5'] [gr-grid=block]:nth-of-type(n + 6) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-5'] {
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-5'] {
        margin-left: 41.66667%;
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-5'] {
        margin-left: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-5'] {
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-6'] {
        -ms-flex-positive: 6;
        flex-grow: 6;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-6'] [gr-grid=block] {
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }

    [gr-grid~=row--block][gr-grid~='lg-6'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-6'] [gr-grid=block]:nth-of-type(n + 7) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-6'] {
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-6'] {
        margin-left: 50%;
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-6'] {
        margin-left: 50%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-6'] {
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='lg-7'] {
        -ms-flex-positive: 7;
        flex-grow: 7;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-7'] [gr-grid=block] {
        -ms-flex-preferred-size: 14.28571%;
        flex-basis: 14.28571%;
        max-width: 14.28571%;
    }

    [gr-grid~=row--block][gr-grid~='lg-7'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-7'] [gr-grid=block]:nth-of-type(n + 8) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-7'] {
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-7'] {
        margin-left: 58.33333%;
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-7'] {
        margin-left: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-7'] {
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-8'] {
        -ms-flex-positive: 8;
        flex-grow: 8;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-8'] [gr-grid=block] {
        -ms-flex-preferred-size: 12.5%;
        flex-basis: 12.5%;
        max-width: 12.5%;
    }

    [gr-grid~=row--block][gr-grid~='lg-8'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-8'] [gr-grid=block]:nth-of-type(n + 9) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-8'] {
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-8'] {
        margin-left: 66.66667%;
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-8'] {
        margin-left: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-8'] {
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-9'] {
        -ms-flex-positive: 9;
        flex-grow: 9;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-9'] [gr-grid=block] {
        -ms-flex-preferred-size: 11.11111%;
        flex-basis: 11.11111%;
        max-width: 11.11111%;
    }

    [gr-grid~=row--block][gr-grid~='lg-9'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-9'] [gr-grid=block]:nth-of-type(n + 10) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-9'] {
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-9'] {
        margin-left: 75%;
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-9'] {
        margin-left: 75%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-9'] {
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='lg-10'] {
        -ms-flex-positive: 10;
        flex-grow: 10;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-10'] [gr-grid=block] {
        -ms-flex-preferred-size: 10%;
        flex-basis: 10%;
        max-width: 10%;
    }

    [gr-grid~=row--block][gr-grid~='lg-10'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-10'] [gr-grid=block]:nth-of-type(n + 11) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-10'] {
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-10'] {
        margin-left: 83.33333%;
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-10'] {
        margin-left: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-10'] {
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='lg-11'] {
        -ms-flex-positive: 11;
        flex-grow: 11;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-11'] [gr-grid=block] {
        -ms-flex-preferred-size: 9.09091%;
        flex-basis: 9.09091%;
        max-width: 9.09091%;
    }

    [gr-grid~=row--block][gr-grid~='lg-11'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-11'] [gr-grid=block]:nth-of-type(n + 12) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-11'] {
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-11'] {
        margin-left: 91.66667%;
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-11'] {
        margin-left: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-11'] {
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='lg-12'] {
        -ms-flex-positive: 12;
        flex-grow: 12;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-12'] [gr-grid=block] {
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }

    [gr-grid~=row--block][gr-grid~='lg-12'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='lg-12'] [gr-grid=block]:nth-of-type(n + 13) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-left-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='lg-offset-right-12'] {
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='lg-offset-12'] {
        margin-left: 100%;
        margin-right: 100%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-left-12'] {
        margin-left: 100%;
    }

    [gr-grid~=row] [gr-grid~='lg-offset-right-12'] {
        margin-right: 100%;
    }
}

@media only screen and (min-width: 1400px) {
    [gr-grid~=row] [gr-grid~='xl-1'] {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-1'] [gr-grid=block] {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    [gr-grid~=row--block][gr-grid~='xl-1'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-1'] [gr-grid=block]:nth-of-type(n + 2) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-1'] {
        margin-left: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-1'] {
        margin-right: calc((100% / 12 * 1) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-1'] {
        margin-left: 8.33333%;
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-1'] {
        margin-left: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-1'] {
        margin-right: 8.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-2'] {
        -ms-flex-positive: 2;
        flex-grow: 2;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-2'] [gr-grid=block] {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    [gr-grid~=row--block][gr-grid~='xl-2'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-2'] [gr-grid=block]:nth-of-type(n + 3) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-2'] {
        margin-left: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-2'] {
        margin-right: calc((100% / 12 * 2) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-2'] {
        margin-left: 16.66667%;
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-2'] {
        margin-left: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-2'] {
        margin-right: 16.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-3'] {
        -ms-flex-positive: 3;
        flex-grow: 3;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-3'] [gr-grid=block] {
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    [gr-grid~=row--block][gr-grid~='xl-3'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-3'] [gr-grid=block]:nth-of-type(n + 4) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-3'] {
        margin-left: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-3'] {
        margin-right: calc((100% / 12 * 3) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-3'] {
        margin-left: 25%;
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-3'] {
        margin-left: 25%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-3'] {
        margin-right: 25%;
    }

    [gr-grid~=row] [gr-grid~='xl-4'] {
        -ms-flex-positive: 4;
        flex-grow: 4;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-4'] [gr-grid=block] {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    [gr-grid~=row--block][gr-grid~='xl-4'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-4'] [gr-grid=block]:nth-of-type(n + 5) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-4'] {
        margin-left: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-4'] {
        margin-right: calc((100% / 12 * 4) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-4'] {
        margin-left: 33.33333%;
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-4'] {
        margin-left: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-4'] {
        margin-right: 33.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-5'] {
        -ms-flex-positive: 5;
        flex-grow: 5;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-5'] [gr-grid=block] {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    [gr-grid~=row--block][gr-grid~='xl-5'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-5'] [gr-grid=block]:nth-of-type(n + 6) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-5'] {
        margin-left: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-5'] {
        margin-right: calc((100% / 12 * 5) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-5'] {
        margin-left: 41.66667%;
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-5'] {
        margin-left: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-5'] {
        margin-right: 41.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-6'] {
        -ms-flex-positive: 6;
        flex-grow: 6;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-6'] [gr-grid=block] {
        -ms-flex-preferred-size: 16.66667%;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
    }

    [gr-grid~=row--block][gr-grid~='xl-6'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-6'] [gr-grid=block]:nth-of-type(n + 7) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-6'] {
        margin-left: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-6'] {
        margin-right: calc((100% / 12 * 6) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-6'] {
        margin-left: 50%;
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-6'] {
        margin-left: 50%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-6'] {
        margin-right: 50%;
    }

    [gr-grid~=row] [gr-grid~='xl-7'] {
        -ms-flex-positive: 7;
        flex-grow: 7;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-7'] [gr-grid=block] {
        -ms-flex-preferred-size: 14.28571%;
        flex-basis: 14.28571%;
        max-width: 14.28571%;
    }

    [gr-grid~=row--block][gr-grid~='xl-7'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-7'] [gr-grid=block]:nth-of-type(n + 8) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-7'] {
        margin-left: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-7'] {
        margin-right: calc((100% / 12 * 7) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-7'] {
        margin-left: 58.33333%;
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-7'] {
        margin-left: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-7'] {
        margin-right: 58.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-8'] {
        -ms-flex-positive: 8;
        flex-grow: 8;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-8'] [gr-grid=block] {
        -ms-flex-preferred-size: 12.5%;
        flex-basis: 12.5%;
        max-width: 12.5%;
    }

    [gr-grid~=row--block][gr-grid~='xl-8'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-8'] [gr-grid=block]:nth-of-type(n + 9) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-8'] {
        margin-left: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-8'] {
        margin-right: calc((100% / 12 * 8) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-8'] {
        margin-left: 66.66667%;
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-8'] {
        margin-left: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-8'] {
        margin-right: 66.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-9'] {
        -ms-flex-positive: 9;
        flex-grow: 9;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-9'] [gr-grid=block] {
        -ms-flex-preferred-size: 11.11111%;
        flex-basis: 11.11111%;
        max-width: 11.11111%;
    }

    [gr-grid~=row--block][gr-grid~='xl-9'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-9'] [gr-grid=block]:nth-of-type(n + 10) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-9'] {
        margin-left: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-9'] {
        margin-right: calc((100% / 12 * 9) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-9'] {
        margin-left: 75%;
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-9'] {
        margin-left: 75%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-9'] {
        margin-right: 75%;
    }

    [gr-grid~=row] [gr-grid~='xl-10'] {
        -ms-flex-positive: 10;
        flex-grow: 10;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-10'] [gr-grid=block] {
        -ms-flex-preferred-size: 10%;
        flex-basis: 10%;
        max-width: 10%;
    }

    [gr-grid~=row--block][gr-grid~='xl-10'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-10'] [gr-grid=block]:nth-of-type(n + 11) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-10'] {
        margin-left: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-10'] {
        margin-right: calc((100% / 12 * 10) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-10'] {
        margin-left: 83.33333%;
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-10'] {
        margin-left: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-10'] {
        margin-right: 83.33333%;
    }

    [gr-grid~=row] [gr-grid~='xl-11'] {
        -ms-flex-positive: 11;
        flex-grow: 11;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-11'] [gr-grid=block] {
        -ms-flex-preferred-size: 9.09091%;
        flex-basis: 9.09091%;
        max-width: 9.09091%;
    }

    [gr-grid~=row--block][gr-grid~='xl-11'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-11'] [gr-grid=block]:nth-of-type(n + 12) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-11'] {
        margin-left: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-11'] {
        margin-right: calc((100% / 12 * 11) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-11'] {
        margin-left: 91.66667%;
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-11'] {
        margin-left: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-11'] {
        margin-right: 91.66667%;
    }

    [gr-grid~=row] [gr-grid~='xl-12'] {
        -ms-flex-positive: 12;
        flex-grow: 12;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-12'] [gr-grid=block] {
        -ms-flex-preferred-size: 8.33333%;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
    }

    [gr-grid~=row--block][gr-grid~='xl-12'] [gr-grid=block]:nth-of-type(n) {
        margin-top: 0;
    }

    [gr-grid~=row--block][gr-grid~='xl-12'] [gr-grid=block]:nth-of-type(n + 13) {
        margin-top: 30px;
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-left-12'] {
        margin-left: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row--block][gr-grid~='xl-offset-right-12'] {
        margin-right: calc((100% / 12 * 12) - (30px / 2));
    }

    [gr-grid~=row] [gr-grid~='xl-offset-12'] {
        margin-left: 100%;
        margin-right: 100%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-left-12'] {
        margin-left: 100%;
    }

    [gr-grid~=row] [gr-grid~='xl-offset-right-12'] {
        margin-right: 100%;
    }
}
