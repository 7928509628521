.icon {
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border-radius: 1em;
    text-align: center;
    border: 2px solid color(brand, secondary);
    color: color(brand, secondary);
    font-size: 3rem;
    font-weight: 700;
	&:before {
		line-height: inherit;
		font-size: 2.5rem;
        font-weight: 400;
    }
}
