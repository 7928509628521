.stats {
    text-align: center;
    .stat {
        font: 300 1.125em / 1.3 $ff--primary;
        position: relative;
        .icon {
            display: block;
            margin: .75em auto;
        }
        padding-left: $gutter-width / 2;
        padding-right: $gutter-width / 2;
        .h3 {
            text-transform: uppercase;
            font-weight: 300;
        }
        &__cta {
            color: color(brand, secondary);
            cursor: pointer;
            font-size: 2em;
            font-weight: 700;
        }
    }
    .stat__flip {
        @extend .entry-box;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: rotateX(90deg) translate(0%, -50%);
        transform-origin: 0% 0%;
        z-index: 1;
        opacity: 0;
        transition: all 0.6s ease;
    }

    .stat:hover .stat__flip {
        transform: rotateX(0deg) translate(0, -50%);
        opacity: 1;
        transition-delay: 0.35s;
    }
}
